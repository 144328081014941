import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { FaPlus } from 'react-icons/fa';
import heroIptvTroubleshooting from '../assets/hero-iptv-troubleshooting.jpg';
import heroIptvTroubleshootingMobile from '../assets/compressed/mobile/hero-iptv-troubleshooting.jpg';
import heroIptvTroubleshootingTablet from '../assets/compressed/tablet/hero-iptv-troubleshooting.jpg';
import './IptvTroubleshooting.css';
import ForumDiscussion from '../components/Forum/ForumDiscussion';
import NouvDiscModal from '../components/Forum/NouvDiscModal';
import useForumPagination from '../hooks/useForumPagination';

const IptvTroubleshooting = () => {
    const [showModal, setShowModal] = useState(false);
    const { currentUser } = useAuth();
    const {
        discussions,
        loading,
        hasMore,
        loadMore,
        refreshDiscussions
    } = useForumPagination('iptv-troubleshooting-discussions');

    return (
        <main className="iptv-troubleshooting-page">
            <Helmet>
                <title>Problèmes & Dépannage IPTV - Solutions Complètes - Tech Passion</title>
                <meta name="description" content="Solutions aux problèmes IPTV courants : buffering, connexion, qualité vidéo, son, sous-titres et compatibilité. Guide de dépannage complet avec solutions étape par étape pour une expérience IPTV optimale." />
                <link rel="canonical" href="https://techpassion.fr/problemes-depannage-iptv" />
                <meta name="keywords" content="dépannage IPTV, problèmes IPTV, solutions IPTV, buffering IPTV, erreurs IPTV, résolution problèmes IPTV, maintenance IPTV, optimisation IPTV" />
                <meta property="og:title" content="Problèmes & Dépannage IPTV - Solutions Complètes - Tech Passion" />
                <meta property="og:description" content="Résolvez tous vos problèmes IPTV : guide complet de dépannage avec solutions pratiques pour une meilleure expérience de streaming." />
                <meta property="og:url" content="https://techpassion.fr/problemes-depannage-iptv" />
                <meta property="og:type" content="website" />
            </Helmet>

            <section className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>Problèmes & Dépannage IPTV</h1>
                        <p>Solutions aux problèmes courants et guide de dépannage pour votre service IPTV</p>
                    </div>
                </div>
                <img 
                    className="hero-image"
                    src={heroIptvTroubleshooting}
                    srcSet={`${heroIptvTroubleshootingMobile} 480w, ${heroIptvTroubleshootingTablet} 768w, ${heroIptvTroubleshooting} 1024w`}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    alt="Outils de diagnostic IPTV avec analyse de flux et résolution des problèmes de streaming"
                    loading="lazy"
                />
            </section>

            <section className="discussions-section">
                {loading ? (
                    <div className="loading">Chargement des discussions...</div>
                ) : (
                    <div className="discussions-container">
                        {discussions.map(discussion => (
                            <ForumDiscussion
                                key={discussion.id}
                                discussion={discussion}
                                collectionName="iptv-troubleshooting-discussions"
                                onReply={refreshDiscussions}
                            />
                        ))}
                    </div>
                )}

                {hasMore && (
                    <div className="load-more-container">
                        <button 
                            className="load-more-btn"
                            onClick={loadMore}
                        >
                            Voir plus
                        </button>
                    </div>
                )}
            </section>

            {currentUser && (
                <button 
                    className="new-discussion-button" 
                    title="Nouvelle discussion"
                    onClick={() => setShowModal(true)}
                >
                    <FaPlus />
                </button>
            )}

            <NouvDiscModal 
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                forumId="iptv-troubleshooting-discussions"
                onDiscussionUpdate={refreshDiscussions}
            />
        </main>
    );
};

export default IptvTroubleshooting;
