import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { FaPlus } from 'react-icons/fa';
import heroCloudProtection from '../assets/hero-cloud-protection.jpg';
import heroCloudProtectionMobile from '../assets/compressed/mobile/hero-cloud-protection.jpg';
import heroCloudProtectionTablet from '../assets/compressed/tablet/hero-cloud-protection.jpg';
import './CloudProtection.css';
import ForumDiscussion from '../components/Forum/ForumDiscussion';
import NouvDiscModal from '../components/Forum/NouvDiscModal';
import useForumPagination from '../hooks/useForumPagination';

const CloudProtection = () => {
    const [showModal, setShowModal] = useState(false);
    const { currentUser } = useAuth();
    const {
        discussions,
        loading,
        hasMore,
        loadMore,
        refreshDiscussions
    } = useForumPagination('cloud-protection-discussions');

    return (
        <main className="cloud-protection-page">
            <Helmet>
                <title>Protection Cloud - Tech Passion</title>
                <meta name="description" content="Découvrez les solutions de protection cloud : sécurité des données, cryptage, sauvegardes automatiques et protection contre les cybermenaces. Guide complet pour sécuriser vos données dans le cloud." />
                <link rel="canonical" href="https://techpassion.fr/protection-cloud" />
                <meta name="keywords" content="protection cloud, sécurité cloud, cryptage données, sauvegarde cloud, cybersécurité cloud, stockage sécurisé, protection données, cloud computing sécurité" />
                <meta property="og:title" content="Protection Cloud - Tech Passion" />
                <meta property="og:description" content="Explorez les solutions de protection cloud : sécurité, cryptage et sauvegardes pour protéger efficacement vos données dans le cloud." />
                <meta property="og:url" content="https://techpassion.fr/protection-cloud" />
                <meta property="og:type" content="website" />
            </Helmet>

            <section className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>Protection Cloud</h1>
                        <p>Découvrez les solutions avancées de sécurité pour vos données dans le cloud</p>
                    </div>
                </div>
                <img 
                    className="hero-image"
                    src={heroCloudProtection}
                    srcSet={`${heroCloudProtectionMobile} 480w, ${heroCloudProtectionTablet} 768w, ${heroCloudProtection} 1024w`}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    alt="Protection cloud avec pare-feu intelligent et système de cryptage des données"
                    loading="lazy"
                />
            </section>

            <section className="discussions-section">
                {loading ? (
                    <div className="loading">Chargement des discussions...</div>
                ) : (
                    <div className="discussions-container">
                        {discussions.map(discussion => (
                            <ForumDiscussion
                                key={discussion.id}
                                discussion={discussion}
                                collectionName="cloud-protection-discussions"
                                onReply={refreshDiscussions}
                            />
                        ))}
                    </div>
                )}

                {hasMore && (
                    <div className="load-more-container">
                        <button 
                            className="load-more-btn"
                            onClick={loadMore}
                        >
                            Voir plus
                        </button>
                    </div>
                )}
            </section>

            {currentUser && (
                <button 
                    className="new-discussion-button" 
                    title="Nouvelle discussion"
                    onClick={() => setShowModal(true)}
                >
                    <FaPlus />
                </button>
            )}

            <NouvDiscModal 
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                forumId="cloud-protection-discussions"
                onDiscussionUpdate={refreshDiscussions}
            />
        </main>
    );
};

export default CloudProtection;
