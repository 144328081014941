import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import heroHightech from '../assets/hero-hightech-new.jpg';
import heroHightechMobile from '../assets/compressed/mobile/hero-hightech-new.jpg';
import heroHightechTablet from '../assets/compressed/tablet/hero-hightech-new.jpg';
import smartHomeIcon from '../assets/icons/smart-home-icon.svg';
import electricMobilityIcon from '../assets/icons/electric-mobility-icon.svg';
import cybersecurityIcon from '../assets/icons/cybersecurity-icon.svg';
import greenTechIcon from '../assets/icons/green-tech-icon.svg';
import './HighTech.css';

const HighTech = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.hash) {
            const element = document.querySelector(location.hash);
            if (element) {
                setTimeout(() => {
                    element.scrollIntoView({ behavior: 'smooth' });
                }, 100);
            }
        }
    }, [location]);

    return (
        <main>
            {/* Balises SEO */}
            <Helmet>
                <title>High-Tech - Tech Passion</title>
                <meta name="description" content="Explorez les dernières innovations en matière de maison connectée, mobilité électrique, cybersécurité et technologies vertes. Restez à la pointe de l'actualité high-tech." />
                <link rel="canonical" href="https://techpassion.fr/high-tech" />
                <meta name="keywords" content="high-tech, maison connectée, mobilité électrique, cybersécurité, tech verte, innovation technologique" />
                <meta property="og:title" content="High-Tech - Tech Passion" />
                <meta property="og:description" content="Explorez les dernières innovations en matière de maison connectée, mobilité électrique, cybersécurité et technologies vertes." />
                <meta property="og:url" content="https://techpassion.fr/high-tech" />
                <meta property="og:type" content="website" />
            </Helmet>

            {/* Hero Section */}
            <section className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>Explorez l'Univers High-Tech</h1>
                        <p className="hero-subtitle">Découvrez les dernières innovations technologiques et restez à la pointe de l'actualité high-tech</p>
                    </div>
                </div>
                <img 
                    className="hero-image" 
                    src={heroHightech}
                    srcSet={`${heroHightechMobile} 480w, ${heroHightechTablet} 768w, ${heroHightech} 1024w`}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    alt="Présentation visuelle des dernières innovations technologiques et gadgets high-tech modernes"
                    loading="lazy"
                />
            </section>

            <section className="page-content">
                {/* Smart Home Section */}
                <div className="category-section" id="smart-home">
                    <div className="section-header">
                        <img src={smartHomeIcon} alt="Icône représentant une maison intelligente avec des éléments connectés" className="section-icon" />
                        <div className="section-text">
                            <h2>Smart Home</h2>
                            <p>La maison connectée de demain</p>
                        </div>
                    </div>
                    <div className="content-grid">
                        <article className="tech-card" onClick={() => navigate('/high-tech/smart-lighting')} style={{ cursor: 'pointer' }}>
                            <h3>Éclairage Intelligent</h3>
                            <p>Contrôlez votre éclairage à distance</p>
                        </article>
                        <article className="tech-card" onClick={() => navigate('/high-tech/connected-security')} style={{ cursor: 'pointer' }}>
                            <h3>Sécurité Connectée</h3>
                            <p>Surveillez votre maison en temps réel</p>
                        </article>
                        <article className="tech-card" onClick={() => navigate('/high-tech/smart-thermostats')} style={{ cursor: 'pointer' }}>
                            <h3>Thermostats Smart</h3>
                            <p>Optimisez votre consommation d'énergie</p>
                        </article>
                        <article className="tech-card" onClick={() => navigate('/high-tech/voice-assistants')} style={{ cursor: 'pointer' }}>
                            <h3>Assistants Vocaux</h3>
                            <p>Pilotez votre maison à la voix</p>
                        </article>
                    </div>
                </div>

                {/* Mobilité Électrique Section */}
                <div className="category-section" id="mobilite-electrique">
                    <div className="section-header">
                        <img src={electricMobilityIcon} alt="Icône représentant un véhicule électrique avec symbole de charge" className="section-icon" />
                        <div className="section-text">
                            <h2>Mobilité Électrique</h2>
                            <p>Transport durable et innovant</p>
                        </div>
                    </div>
                    <div className="content-grid">
                        <article className="tech-card" onClick={() => navigate('/high-tech/voitures-electriques')} style={{ cursor: 'pointer' }}>
                            <h3>Voitures Électriques</h3>
                            <p>Les derniers modèles et innovations</p>
                        </article>
                        <article className="tech-card" onClick={() => navigate('/high-tech/trottinettes')} style={{ cursor: 'pointer' }}>
                            <h3>Trottinettes</h3>
                            <p>Mobilité urbaine électrique</p>
                        </article>
                        <article className="tech-card" onClick={() => navigate('/high-tech/velos-electriques')} style={{ cursor: 'pointer' }}>
                            <h3>Vélos Électriques</h3>
                            <p>Le futur du cyclisme urbain</p>
                        </article>
                        <article className="tech-card" onClick={() => navigate('/high-tech/bornes-recharge')} style={{ cursor: 'pointer' }}>
                            <h3>Bornes de Recharge</h3>
                            <p>Infrastructure et réseaux</p>
                        </article>
                    </div>
                </div>

                {/* Cybersécurité Section */}
                <div className="category-section" id="cybersecurite">
                    <div className="section-header">
                        <img src={cybersecurityIcon} alt="Icône représentant un bouclier de protection avec symbole de sécurité" className="section-icon" />
                        <div className="section-text">
                            <h2>Cybersécurité</h2>
                            <p>Protection des données nouvelle génération</p>
                        </div>
                    </div>
                    <div className="content-grid">
                        <article className="tech-card" onClick={() => navigate('/high-tech/antivirus-ia')} style={{ cursor: 'pointer' }}>
                            <h3>Antivirus IA</h3>
                            <p>Protection intelligente contre les menaces</p>
                        </article>
                        <article className="tech-card" onClick={() => navigate('/high-tech/vpn-nouvelle-generation')} style={{ cursor: 'pointer' }}>
                            <h3>VPN Nouvelle Génération</h3>
                            <p>Sécurité et confidentialité avancées</p>
                        </article>
                        <article className="tech-card" onClick={() => navigate('/high-tech/authentification-biometrique')} style={{ cursor: 'pointer' }}>
                            <h3>Authentification Biométrique</h3>
                            <p>Sécurité personnalisée</p>
                        </article>
                        <article className="tech-card" onClick={() => navigate('/high-tech/protection-cloud')} style={{ cursor: 'pointer' }}>
                            <h3>Protection Cloud</h3>
                            <p>Sécurisation des données cloud</p>
                        </article>
                    </div>
                </div>

                {/* Green Tech Section */}
                <div className="category-section" id="green-tech">
                    <div className="section-header">
                        <img src={greenTechIcon} alt="Icône représentant une maison écologique avec des éléments naturels" className="section-icon" />
                        <div className="section-text">
                            <h2>Green Tech</h2>
                            <p>Technologies pour un avenir durable</p>
                        </div>
                    </div>
                    <div className="content-grid">
                        <article className="tech-card" onClick={() => navigate('/high-tech/energie-solaire')} style={{ cursor: 'pointer' }}>
                            <h3>Énergie Solaire</h3>
                            <p>Solutions photovoltaïques innovantes</p>
                        </article>
                        <article className="tech-card" onClick={() => navigate('/high-tech/recyclage-tech')} style={{ cursor: 'pointer' }}>
                            <h3>Recyclage Tech</h3>
                            <p>Gestion des déchets électroniques</p>
                        </article>
                        <article className="tech-card" onClick={() => navigate('/high-tech/eco-conception')} style={{ cursor: 'pointer' }}>
                            <h3>Éco-conception</h3>
                            <p>Design durable et responsable</p>
                        </article>
                        <article className="tech-card" onClick={() => navigate('/high-tech/smart-grid')} style={{ cursor: 'pointer' }}>
                            <h3>Smart Grid</h3>
                            <p>Réseaux électriques intelligents</p>
                        </article>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default HighTech;
