import React, { useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import emailjs from '@emailjs/browser';
import SvgCaptcha from '../components/SvgCaptcha';
import heroContact from '../assets/hero-contact.jpg';
import heroContactMobile from '../assets/compressed/mobile/hero-contact.jpg';
import heroContactTablet from '../assets/compressed/tablet/hero-contact.jpg';
import './Contact.css';

// Initialisation de EmailJS avec la clé publique depuis les variables d'environnement
emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY);

const Contact = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({ type: '', message: '' });
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!isCaptchaValid) {
      setSubmitStatus({
        type: 'error',
        message: 'Veuillez compléter le captcha correctement'
      });
      return;
    }

    setIsSubmitting(true);
    setSubmitStatus({ type: '', message: '' });

    try {
      // Préparation des paramètres du template
      const templateParams = {
        to_name: 'Admin', // Le destinataire (vous)
        from_name: formData.name,
        from_email: formData.email,
        subject: formData.subject,
        message: formData.message
      };

      // Envoi de l'email avec EmailJS
      const emailResult = await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      );

      if (emailResult.status === 200) {
        // Réinitialisation du formulaire
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: ''
        });
        setIsCaptchaValid(false);

        setSubmitStatus({
          type: 'success',
          message: 'Votre message a été envoyé avec succès !'
        });
      }
    } catch (error) {
      console.error('Erreur lors de l\'envoi:', error);
      setSubmitStatus({
        type: 'error',
        message: 'Une erreur est survenue lors de l\'envoi du message.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <main>
      {/* Balises SEO */}
      <Helmet>
        <title>Contact - Tech Passion</title>
        <meta name="description" content="Contactez l'équipe Tech Passion pour toute question, suggestion ou collaboration. Nous sommes à votre écoute pour échanger sur les dernières innovations technologiques." />
        <link rel="canonical" href="https://techpassion.fr/contact" />
        <meta name="keywords" content="contact tech passion, support technique, collaboration tech, questions tech, assistance technologique" />
        <meta property="og:title" content="Contact - Tech Passion" />
        <meta property="og:description" content="Contactez l'équipe Tech Passion pour toute question, suggestion ou collaboration. Nous sommes à votre écoute." />
        <meta property="og:url" content="https://techpassion.fr/contact" />
        <meta property="og:type" content="website" />
      </Helmet>

      {/* Hero Section */}
      <section className="hero">
        <div className="hero-left">
          <div className="hero-content">
            <h1>Contact</h1>
            <p className="hero-subtitle">Restons en contact</p>
          </div>
        </div>
        <img 
          className="hero-image" 
          src={heroContact}
          srcSet={`${heroContactMobile} 480w, ${heroContactTablet} 768w, ${heroContact} 1024w`}
          sizes="(max-width: 768px) 100vw, 50vw"
          alt="Un MacBook Pro et un téléphone fixe sur un bureau de travail"
          loading="lazy"
        />
      </section>

      {/* Formulaire de contact */}
      <section className="contact-section">
        <div className="contact-form-container">
          <form ref={form} onSubmit={handleSubmit} className="contact-form">
            <div className="form-group">
              <label htmlFor="name">Nom</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="subject">Sujet</label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>

            {/* Captcha */}
            <SvgCaptcha onValidate={setIsCaptchaValid} />

            {submitStatus.message && (
              <div className={`alert ${submitStatus.type}`}>
                {submitStatus.message}
              </div>
            )}
            
            <button 
              type="submit" 
              className="submit-button-contact"
              disabled={isSubmitting || !isCaptchaValid}
            >
              {isSubmitting ? 'Envoi en cours...' : 'Envoyer'}
            </button>
          </form>
        </div>

        {/* Carte d'informations de contact */}
        <div className="contact-info">
          <div className="tech-card">
            <h3>Informations de Contact</h3>
            <p>Email: alinpreda01@gmail.com</p>
            <p>Téléphone: +33 6 76 70 37 38</p>
            <p>Adresse: 210 Rue du Grésivaudan, 38420 Le Versoud</p>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Contact;
