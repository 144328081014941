import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { FaPlus } from 'react-icons/fa';
import heroWatches from '../assets/hero-watches.jpg';
import heroWatchesMobile from '../assets/compressed/mobile/hero-watches.jpg';
import heroWatchesTablet from '../assets/compressed/tablet/hero-watches.jpg';
import './Watches.css';
import ForumDiscussion from '../components/Forum/ForumDiscussion';
import NouvDiscModal from '../components/Forum/NouvDiscModal';
import useForumPagination from '../hooks/useForumPagination';

const Watches = () => {
    const [showModal, setShowModal] = useState(false);
    const { currentUser } = useAuth();
    const {
        discussions,
        loading,
        hasMore,
        loadMore,
        refreshDiscussions
    } = useForumPagination('watches-discussions');

    return (
        <main className="watches-page">
            <Helmet>
                <title>Montres Connectées - Comparatif et Tests - Tech Passion</title>
                <meta name="description" content="Guide complet sur les montres connectées : comparatif des meilleurs modèles, tests approfondis, fonctionnalités santé et sport, compatibilité smartphone et autonomie. Trouvez la montre connectée idéale." />
                <link rel="canonical" href="https://techpassion.fr/montres-connectees" />
                <meta name="keywords" content="montres connectées, smartwatch, montre intelligente, Apple Watch, Samsung Galaxy Watch, santé connectée, sport connecté, comparatif montres" />
                <meta property="og:title" content="Montres Connectées - Comparatif et Tests - Tech Passion" />
                <meta property="og:description" content="Découvrez notre guide complet des montres connectées : comparatifs, tests et conseils pour choisir la smartwatch adaptée à vos besoins." />
                <meta property="og:url" content="https://techpassion.fr/montres-connectees" />
                <meta property="og:type" content="website" />
            </Helmet>

            <section className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>Montres Connectées</h1>
                        <p>Discussions sur les montres connectées, smartwatches et leurs fonctionnalités</p>
                    </div>
                </div>
                <img 
                    className="hero-image"
                    src={heroWatches}
                    srcSet={`${heroWatchesMobile} 480w, ${heroWatchesTablet} 768w, ${heroWatches} 1024w`}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    alt="Montres connectées avec écrans tactiles, capteurs de santé et fonctionnalités de communication"
                    loading="lazy"
                />
            </section>

            <section className="discussions-section">
                {loading ? (
                    <div className="loading">Chargement des discussions...</div>
                ) : (
                    <div className="discussions-container">
                        {discussions.map(discussion => (
                            <ForumDiscussion
                                key={discussion.id}
                                discussion={discussion}
                                collectionName="watches-discussions"
                                onReply={refreshDiscussions}
                            />
                        ))}
                    </div>
                )}

                {hasMore && (
                    <div className="load-more-container">
                        <button 
                            className="load-more-btn"
                            onClick={loadMore}
                        >
                            Voir plus
                        </button>
                    </div>
                )}
            </section>

            {currentUser && (
                <button 
                    className="new-discussion-button" 
                    title="Nouvelle discussion"
                    onClick={() => setShowModal(true)}
                >
                    <FaPlus />
                </button>
            )}

            <NouvDiscModal 
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                forumId="watches-discussions"
                onDiscussionUpdate={refreshDiscussions}
            />
        </main>
    );
};

export default Watches;
