import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { FaPlus } from 'react-icons/fa';
// eslint-disable-next-line no-unused-vars
import heroConnectedSecurity from '../assets/hero-security.jpg';
import heroConnectedSecurityMobile from '../assets/compressed/mobile/hero-security.jpg';
import heroConnectedSecurityTablet from '../assets/compressed/tablet/hero-security.jpg';
import './ConnectedSecurity.css';
import ForumDiscussion from '../components/Forum/ForumDiscussion';
import NouvDiscModal from '../components/Forum/NouvDiscModal';
import useForumPagination from '../hooks/useForumPagination';

const ConnectedSecurity = () => {
    const [showModal, setShowModal] = useState(false);
    const { currentUser } = useAuth();
    const {
        discussions,
        loading,
        hasMore,
        loadMore,
        refreshDiscussions
    } = useForumPagination('connected-security-discussions');

    return (
        <main className="connected-security-page">
            <Helmet>
                <title>Sécurité Connectée - Tech Passion</title>
                <meta name="description" content="Protégez votre maison avec les dernières solutions de sécurité connectée. Découvrez les caméras de surveillance, systèmes d'alarme intelligents et serrures connectées pour une protection optimale." />
                <link rel="canonical" href="https://techpassion.fr/securite-connectee" />
                <meta name="keywords" content="sécurité connectée, caméras surveillance, alarmes intelligentes, serrures connectées, vidéosurveillance, domotique sécurité, protection maison" />
                <meta property="og:title" content="Sécurité Connectée - Tech Passion" />
                <meta property="og:description" content="Protégez votre maison avec les dernières solutions de sécurité connectée. Découvrez nos guides et conseils pour une protection optimale." />
                <meta property="og:url" content="https://techpassion.fr/securite-connectee" />
                <meta property="og:type" content="website" />
            </Helmet>

            <section className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>Sécurité Connectée</h1>
                        <p>Explorez et discutez des dernières innovations en matière de sécurité connectée. Partagez vos expériences, posez vos questions et découvrez les meilleures pratiques pour protéger votre maison intelligente.</p>
                    </div>
                </div>

                <div className="hero-right">
                    <img 
                        className="hero-image"
                        src={heroConnectedSecurity}
                        srcSet={`${heroConnectedSecurityMobile} 480w, ${heroConnectedSecurityTablet} 768w, ${heroConnectedSecurity} 1024w`}
                        sizes="(max-width: 768px) 100vw, 50vw"
                        alt="Système de sécurité connecté avec caméras de surveillance, détecteurs de mouvement et contrôle d'accès intelligent"
                        loading="lazy"
                    />
                </div>
            </section>

            <section className="discussions-section">
                {loading ? (
                    <div className="loading">Chargement des discussions...</div>
                ) : (
                    <div className="discussions-container">
                        {discussions.map(discussion => (
                            <ForumDiscussion
                                key={discussion.id}
                                discussion={discussion}
                                collectionName="connected-security-discussions"
                                onReply={refreshDiscussions}
                            />
                        ))}
                    </div>
                )}

                {hasMore && (
                    <div className="load-more-container">
                        <button 
                            className="load-more-btn"
                            onClick={loadMore}
                        >
                            Voir plus
                        </button>
                    </div>
                )}
            </section>

            {currentUser && (
                <button 
                    className="new-discussion-button" 
                    title="Nouvelle discussion"
                    onClick={() => setShowModal(true)}
                >
                    <FaPlus />
                </button>
            )}

            <NouvDiscModal 
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                forumId="connected-security-discussions"
                onDiscussionUpdate={refreshDiscussions}
            />
        </main>
    );
};

export default ConnectedSecurity;
