import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { FaPlus } from 'react-icons/fa';
import heroIptvSecurity from '../assets/hero-iptv-security.jpg';
import heroIptvSecurityMobile from '../assets/compressed/mobile/hero-iptv-security.jpg';
import heroIptvSecurityTablet from '../assets/compressed/tablet/hero-iptv-security.jpg';
import './IptvSecurity.css';
import ForumDiscussion from '../components/Forum/ForumDiscussion';
import NouvDiscModal from '../components/Forum/NouvDiscModal';
import useForumPagination from '../hooks/useForumPagination';

const IptvSecurity = () => {
    const [showModal, setShowModal] = useState(false);
    const { currentUser } = useAuth();
    const {
        discussions,
        loading,
        hasMore,
        loadMore,
        refreshDiscussions
    } = useForumPagination('iptv-security-discussions');

    return (
        <main className="iptv-security-page">
            <Helmet>
                <title>Sécurité et Utilisation Légale IPTV - Guide Complet - Tech Passion</title>
                <meta name="description" content="Guide essentiel sur la sécurité et l'utilisation légale des services IPTV : bonnes pratiques, protection des données, conformité légale et choix de fournisseurs fiables. Informations complètes pour une utilisation sûre et responsable." />
                <link rel="canonical" href="https://techpassion.fr/securite-utilisation-legale-iptv" />
                <meta name="keywords" content="sécurité IPTV, IPTV légal, protection IPTV, conformité IPTV, fournisseurs légaux IPTV, droits d'auteur IPTV, confidentialité IPTV, réglementation IPTV" />
                <meta property="og:title" content="Sécurité et Utilisation Légale IPTV - Guide Complet - Tech Passion" />
                <meta property="og:description" content="Découvrez comment utiliser les services IPTV en toute sécurité et légalité : guide complet avec conseils et bonnes pratiques." />
                <meta property="og:url" content="https://techpassion.fr/securite-utilisation-legale-iptv" />
                <meta property="og:type" content="website" />
            </Helmet>

            <section className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>Sécurité et Utilisation Légale IPTV</h1>
                        <p>Guide sur la sécurité et les bonnes pratiques d'utilisation des services IPTV</p>
                    </div>
                </div>
                <img 
                    className="hero-image"
                    src={heroIptvSecurity}
                    srcSet={`${heroIptvSecurityMobile} 480w, ${heroIptvSecurityTablet} 768w, ${heroIptvSecurity} 1024w`}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    alt="Système de sécurité IPTV avec cryptage des flux et protection contre le piratage"
                    loading="lazy"
                />
            </section>

            <section className="discussions-section">
                {loading ? (
                    <div className="loading">Chargement des discussions...</div>
                ) : (
                    <div className="discussions-container">
                        {discussions.map(discussion => (
                            <ForumDiscussion
                                key={discussion.id}
                                discussion={discussion}
                                collectionName="iptv-security-discussions"
                                onReply={refreshDiscussions}
                            />
                        ))}
                    </div>
                )}

                {hasMore && (
                    <div className="load-more-container">
                        <button 
                            className="load-more-btn"
                            onClick={loadMore}
                        >
                            Voir plus
                        </button>
                    </div>
                )}
            </section>

            {currentUser && (
                <button 
                    className="new-discussion-button" 
                    title="Nouvelle discussion"
                    onClick={() => setShowModal(true)}
                >
                    <FaPlus />
                </button>
            )}

            <NouvDiscModal 
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                forumId="iptv-security-discussions"
                onDiscussionUpdate={refreshDiscussions}
            />
        </main>
    );
};

export default IptvSecurity;
