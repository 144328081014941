import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { FaPlus } from 'react-icons/fa';
import heroChargingStations from '../assets/hero-charging-stations.jpg';
import heroChargingStationsMobile from '../assets/compressed/mobile/hero-charging-stations.jpg';
import heroChargingStationsTablet from '../assets/compressed/tablet/hero-charging-stations.jpg';
import './ChargingStations.css';
import ForumDiscussion from '../components/Forum/ForumDiscussion';
import NouvDiscModal from '../components/Forum/NouvDiscModal';
import useForumPagination from '../hooks/useForumPagination';

const ChargingStations = () => {
    const [showModal, setShowModal] = useState(false);
    const { currentUser } = useAuth();
    const {
        discussions,
        loading,
        hasMore,
        loadMore,
        refreshDiscussions
    } = useForumPagination('charging-stations-discussions');

    return (
        <main className="charging-stations-page">
            <Helmet>
                <title>Bornes de Recharge - Tech Passion</title>
                <meta name="description" content="Tout savoir sur les bornes de recharge pour véhicules électriques : types de connecteurs, puissances, réseaux de recharge et installation à domicile. Guide complet pour une recharge efficace." />
                <link rel="canonical" href="https://techpassion.fr/bornes-de-recharge" />
                <meta name="keywords" content="bornes de recharge, recharge électrique, stations de recharge, charge rapide, charge à domicile, connecteurs EV, réseaux de recharge, infrastructure électrique" />
                <meta property="og:title" content="Bornes de Recharge - Tech Passion" />
                <meta property="og:description" content="Découvrez tout sur les bornes de recharge : types, réseaux, installation et conseils pour une recharge optimale de votre véhicule électrique." />
                <meta property="og:url" content="https://techpassion.fr/bornes-de-recharge" />
                <meta property="og:type" content="website" />
            </Helmet>

            <section className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>Bornes de Recharge</h1>
                        <p>Explorez l'infrastructure de recharge pour véhicules électriques et son évolution</p>
                    </div>
                </div>
                <img 
                    className="hero-image"
                    src={heroChargingStations}
                    srcSet={`${heroChargingStationsMobile} 480w, ${heroChargingStationsTablet} 768w, ${heroChargingStations} 1024w`}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    alt="Station de recharge électrique moderne avec plusieurs bornes rapides et interface de paiement intégrée"
                    loading="lazy"
                />
            </section>

            <section className="discussions-section">
                {loading ? (
                    <div className="loading">Chargement des discussions...</div>
                ) : (
                    <div className="discussions-container">
                        {discussions.map(discussion => (
                            <ForumDiscussion
                                key={discussion.id}
                                discussion={discussion}
                                collectionName="charging-stations-discussions"
                                onReply={refreshDiscussions}
                            />
                        ))}
                    </div>
                )}

                {hasMore && (
                    <div className="load-more-container">
                        <button 
                            className="load-more-btn"
                            onClick={loadMore}
                        >
                            Voir plus
                        </button>
                    </div>
                )}
            </section>

            {currentUser && (
                <button 
                    className="new-discussion-button" 
                    title="Nouvelle discussion"
                    onClick={() => setShowModal(true)}
                >
                    <FaPlus />
                </button>
            )}

            <NouvDiscModal 
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                forumId="charging-stations-discussions"
                onDiscussionUpdate={refreshDiscussions}
            />
        </main>
    );
};

export default ChargingStations;
