import { useState, useEffect, useCallback } from 'react';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

const useForumPagination = (collectionName, itemsPerPage = 10) => {
    const [discussions, setDiscussions] = useState([]);
    const [pinnedDiscussions, setPinnedDiscussions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [allDiscussions, setAllDiscussions] = useState([]);

    // Fonction pour charger toutes les discussions
    const fetchAllDiscussions = useCallback(async () => {
        try {
            setLoading(true);
            const discussionsRef = collection(db, collectionName);
            const q = query(discussionsRef, orderBy('createdAt', 'desc'));
            const querySnapshot = await getDocs(q);

            const discussionsData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                pinned: doc.data().pinned || false,
                createdAt: doc.data().createdAt || { seconds: 0 }
            }));

            // Séparer les discussions épinglées des autres
            const pinned = discussionsData.filter(discussion => discussion.pinned);
            const unpinned = discussionsData.filter(discussion => !discussion.pinned);

            setPinnedDiscussions(pinned);
            setAllDiscussions(unpinned);

            // Initialiser les discussions affichées avec les discussions épinglées
            // et les premières discussions non épinglées
            const initialDiscussions = [
                ...pinned,
                ...unpinned.slice(0, itemsPerPage)
            ];

            setDiscussions(initialDiscussions);
            setHasMore(unpinned.length > itemsPerPage);
            setLoading(false);
        } catch (error) {
            console.error("Erreur lors du chargement des discussions:", error);
            setLoading(false);
        }
    }, [collectionName, itemsPerPage]);

    // Charger les discussions au montage du composant
    useEffect(() => {
        fetchAllDiscussions();
    }, [fetchAllDiscussions]);

    // Fonction pour charger plus de discussions
    const loadMore = () => {
        const nextPage = currentPage + 1;
        const startIndex = (currentPage) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        
        const newDiscussions = [
            ...pinnedDiscussions,
            ...allDiscussions.slice(0, endIndex)
        ];

        setDiscussions(newDiscussions);
        setCurrentPage(nextPage);
        setHasMore(endIndex < allDiscussions.length);
    };

    // Fonction pour rafraîchir les discussions
    const refreshDiscussions = () => {
        fetchAllDiscussions();
    };

    return {
        discussions,
        loading,
        hasMore,
        loadMore,
        refreshDiscussions,
        pinnedDiscussions
    };
};

export default useForumPagination;
