import { SplineScene } from "./ui/spline";
import { Card } from "./ui/card";
import { Spotlight } from "./ui/spotlight";
 
export function SplineSceneBasic() {
  return (
    <Card className="w-full h-[400px] bg-transparent relative overflow-hidden border-0">
      <Spotlight
        className="-top-40 left-0 md:left-60 md:-top-20"
        fill="transparent"
      />
      
      <div className="flex h-full">
        <div className="w-full relative" style={{ transform: 'scale(1.1) translateY(-8%)' }}>
          <SplineScene 
            scene="https://prod.spline.design/kZDDjO5HuC9GJUM2/scene.splinecode"
            className="w-full h-full"
          />
        </div>
      </div>
    </Card>
  );
}
