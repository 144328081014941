import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import heroTech from '../assets/hero-tech.jpg';
import heroTechMobile from '../assets/compressed/mobile/hero-tech.jpg';
import heroTechTablet from '../assets/compressed/tablet/hero-tech.jpg';
import trendsIcon from '../assets/icons/trends-icon.svg';
import innovationIcon from '../assets/icons/dev-icon.svg';
import gadgetsIcon from '../assets/icons/gadgets-icon.svg';
import '../styles/critical.css';
import { useAuth } from '../contexts/AuthContext';
import AuthModal from '../components/AuthModal';
import { SplineSceneBasic } from '../components/SplineSceneBasic';

// Chargement asynchrone du CSS non critique
const loadNonCriticalCSS = () => {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = './Home.css';
  link.media = 'print';
  link.onload = function() {
    this.media = 'all';
  };
  document.head.appendChild(link);
};

const Home = () => {
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const { currentUser } = useAuth();
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadNonCriticalCSS();
    
    // Charger les actualités tech via GNews
    const fetchNews = async () => {
      try {
        console.log('Chargement des actualités...');
        const response = await fetch(
          'https://gnews.io/api/v4/search?' + new URLSearchParams({
            q: 'technology',
            lang: 'fr',
            country: 'fr',
            max: '5',
            sortby: 'publishedAt',
            apikey: 'e9da46364db3d54cc3e5c5fddedecc4b'
          })
        );
        
        if (!response.ok) {
          throw new Error(`Erreur HTTP: ${response.status}`);
        }

        const data = await response.json();
        console.log('Actualités reçues:', data);
        
        if (!data.articles || data.articles.length === 0) {
          throw new Error('Aucun article disponible');
        }

        const formattedNews = data.articles.map(article => ({
          title: article.title,
          description: article.description || 'Aucune description disponible',
          url: article.url,
          urlToImage: article.image,
          publishedAt: new Date(article.publishedAt).toLocaleDateString('fr-FR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          }),
          source: article.source.name
        }));

        setNews(formattedNews);
      } catch (err) {
        console.error('Erreur lors du chargement des actualités:', err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  const handleDiscoverClick = () => {
    if (!currentUser) {
      setIsAuthModalOpen(true);
    } else {
      window.location.href = '#trends';
    }
  };

  return (
    <main>
      {/* Balises SEO */}
      <Helmet>
        <title>Accueil - Tech Passion</title>
        <meta name="description" content="Explorez les dernières innovations, découvrez des tutoriels passionnants et restez à jour avec les tendances tech." />
        <link rel="canonical" href="https://techpassion.fr/" />
        <meta name="keywords" content="tech passion, innovation technologique, tutoriels tech, tendances technologiques, actualités tech, high-tech" />
        <meta property="og:title" content="Accueil - Tech Passion" />
        <meta property="og:description" content="Explorez les dernières innovations, découvrez des tutoriels passionnants et restez à jour avec les tendances tech." />
        <meta property="og:url" content="https://techpassion.fr/" />
        <meta property="og:type" content="website" />
      </Helmet>

      {/* Hero Section */}
      <section className="hero">
        <div className="hero-left">
          <div className="hero-content">
            <h1><span style={{ color: '#e63946' }}>Tech</span>Passion</h1>
            <p className="hero-subtitle">Votre guide dans l'univers de la technologie</p>
            <p className="hero-description">Explorez les dernières innovations, découvrez des tutoriels passionnants et restez à jour avec les tendances tech.</p>
            <button className="cta-button" onClick={handleDiscoverClick}>
              {currentUser ? "Explorer" : "Découvrir"}
            </button>
          </div>
        </div>
        <img 
          className="hero-image"
          src={heroTech}
          srcSet={`${heroTechMobile} 480w, ${heroTechTablet} 768w, ${heroTech} 1024w`}
          sizes="(max-width: 768px) 100vw, 50vw"
          alt="Page d'accueil présentant les dernières innovations technologiques et discussions de la communauté tech"
          loading="lazy"
          fetchpriority="auto"
        />
      </section>
      
      {/* Section Présentation */}
      <section className="presentation-section fade-in">
        <div className="container">
          <h2 className="section-title slide-in">Bienvenue sur TechPassion.fr</h2>
          <div className="spline-container">
            <SplineSceneBasic />
          </div>
          <div className="content-block fade-in">
            <h3>On Explore Ensemble les Nouveautés Tech !</h3>
            <p>
              Les innovations, ça bouge tout le temps dans ce domaine. Venez découvrir nos articles qui couvrent l'ensemble 
              de l'univers technologique : les derniers smartphones, les avancées en intelligence artificielle, 
              la cybersécurité, les innovations en informatique et bien plus encore !
            </p>
          </div>

          <div className="content-block fade-in">
            <h3>Des Tutos Pour Tout le Monde</h3>
            <p>
              Que vous soyez expert ou débutant, nos guides pratiques sont conçus pour vous ! Apprenez à maîtriser 
              vos appareils comme un pro, sécurisez vos données et exploitez pleinement le potentiel de vos 
              outils numériques.
            </p>
          </div>

          <div className="content-block fade-in">
            <h3>La Team TechPassion Vous Attend !</h3>
            <p>
              En rejoignant notre communauté, vous intégrez une famille de passionnés de technologie. Échangez, 
              partagez vos expériences et trouvez des réponses à vos questions. Experts comme débutants, 
              chacun a sa place dans notre écosystème !
            </p>
          </div>

          <div className="benefits-block fade-in">
            <h3>Pourquoi Nous Rejoindre ?</h3>
            <ul>
              <li>Des articles exclusifs réservés à nos membres</li>
              <li>Un flux d'actualités personnalisé selon vos centres d'intérêt</li>
              <li>Une communauté active et bienveillante</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Section Actualités Tech */}
      <section className="tech-news-section fade-in">
        <div className="container">
          <h2>Dernières Actualités Tech</h2>
          <div className="tech-news-container">
            {loading ? (
              <div className="loading-message">Chargement des actualités...</div>
            ) : news.length > 0 ? (
              news.map((article, index) => (
                <article key={index} className="tech-news-card">
                  {article.urlToImage && (
                    <img 
                      src={article.urlToImage} 
                      alt={article.title}
                      className="tech-news-image"
                      loading="lazy"
                      onError={(e) => {
                        e.target.style.display = 'none';
                      }}
                    />
                  )}
                  <div className="tech-news-content">
                    <div className="article-meta">
                      <span className="source">{article.source}</span>
                      <span className="date">{article.publishedAt}</span>
                    </div>
                    <h3>{article.title}</h3>
                    <p>{article.description}</p>
                    <a 
                      href={article.url} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="read-more"
                    >
                      Lire la suite
                    </a>
                  </div>
                </article>
              ))
            ) : (
              <div className="error-message">Impossible de charger les actualités pour le moment.</div>
            )}
          </div>
        </div>
      </section>

      {/* Tendances Tech */}
      <div id="trends" className="category-section">
        <div className="section-header">
          <img src={trendsIcon} alt="Icône représentant les tendances technologiques avec un graphique croissant" className="section-icon" />
          <div className="section-text">
            <h2>Tendances Tech</h2>
            <p>Les technologies qui transforment notre quotidien</p>
          </div>
        </div>
        <div className="content-grid">
          <article className="tech-card">
            <Link to="/high-tech#smart-home">
              <div className="card-content">
                <h3>Smart Home</h3>
                <p>La maison connectée de demain</p>
              </div>
            </Link>
          </article>
          <article className="tech-card">
            <Link to="/high-tech#mobilite-electrique">
              <div className="card-content">
                <h3>Mobilité Électrique</h3>
                <p>Transport durable et innovant</p>
              </div>
            </Link>
          </article>
          <article className="tech-card">
            <Link to="/high-tech#cybersecurite">
              <div className="card-content">
                <h3>Cybersécurité</h3>
                <p>Protection des données nouvelle génération</p>
              </div>
            </Link>
          </article>
          <article className="tech-card">
            <Link to="/high-tech#green-tech">
              <div className="card-content">
                <h3>Green Tech</h3>
                <p>Technologies pour un avenir durable</p>
              </div>
            </Link>
          </article>
        </div>
      </div>

      {/* Dernières Innovations */}
      <div className="category-section">
        <div className="section-header">
          <img src={innovationIcon} alt="Icône représentant le développement avec des symboles de code" className="section-icon" />
          <div className="section-text">
            <h2>Développement - Dernières Innovations</h2>
            <p>Découvrez les technologies qui façonnent notre avenir</p>
          </div>
        </div>
        <div className="content-grid">
          <article className="tech-card">
            <Link to="/developpement#intelligence-artificielle">
              <div className="card-content">
                <h3>Intelligence Artificielle</h3>
                <p>Les avancées majeures en IA et leurs applications</p>
              </div>
            </Link>
          </article>
          <article className="tech-card">
            <Link to="/developpement#realite-virtuelle">
              <div className="card-content">
                <h3>Réalité Virtuelle</h3>
                <p>L'avenir de l'immersion numérique</p>
              </div>
            </Link>
          </article>
          <article className="tech-card">
            <Link to="/developpement#informatique-quantique">
              <div className="card-content">
                <h3>Quantum Computing</h3>
                <p>La révolution du calcul quantique</p>
              </div>
            </Link>
          </article>
          <article className="tech-card">
            <Link to="/developpement#blockchain">
              <div className="card-content">
                <h3>Blockchain</h3>
                <p>La technologie qui révolutionne les transactions</p>
              </div>
            </Link>
          </article>
        </div>
      </div>

      {/* Tech & Gadgets */}
      <div className="category-section">
        <div className="section-header">
          <img src={gadgetsIcon} alt="Icône représentant les gadgets et les technologies" className="section-icon" />
          <div className="section-text">
            <h2>Tech & Gadgets</h2>
            <p>Découvrez les derniers gadgets et innovations technologiques</p>
          </div>
        </div>
        <div className="content-grid">
          <article className="tech-card">
            <Link to="/tech-gadgets#smartphones">
              <div className="card-content">
                <h3>Smartphones</h3>
                <p>Les derniers modèles et innovations mobiles</p>
              </div>
            </Link>
          </article>
          <article className="tech-card">
            <Link to="/tech-gadgets#ordinateurs">
              <div className="card-content">
                <h3>Ordinateurs</h3>
                <p>Guide complet sur le matériel informatique</p>
              </div>
            </Link>
          </article>
          <article className="tech-card">
            <Link to="/tech-gadgets#iptv">
              <div className="card-content">
                <h3>IPTV</h3>
                <p>La télévision du futur est déjà là</p>
              </div>
            </Link>
          </article>
          <article className="tech-card">
            <Link to="/tech-gadgets#gadgets">
              <div className="card-content">
                <h3>Gadgets & Innovations</h3>
                <p>Les dernières innovations technologiques</p>
              </div>
            </Link>
          </article>
        </div>
      </div>

      {/* Section CTA */}
      <section className="cta-section">
        <div className="container">
          <div className="cta-block fade-in">
            <p className="cta-text">
              Alors, vous venez ? TechPassion.fr vous attend pour vivre l'aventure tech ensemble !
              La tech, c'est maintenant et c'est avec vous que ça se passe.
            </p>
            <button className="cta-button" onClick={handleDiscoverClick}>
              Rejoignez l'aventure
            </button>
          </div>
        </div>
      </section>

      {/* Modal d'authentification */}
      <AuthModal 
        isOpen={isAuthModalOpen} 
        onClose={() => setIsAuthModalOpen(false)} 
      />
    </main>
  );
};

export default Home;
