import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { FaPlus } from 'react-icons/fa';
import heroIptvSetup from '../assets/hero-iptv-setup.jpg';
import heroIptvSetupMobile from '../assets/compressed/mobile/hero-iptv-setup.jpg';
import heroIptvSetupTablet from '../assets/compressed/tablet/hero-iptv-setup.jpg';
import './IptvSetup.css';
import ForumDiscussion from '../components/Forum/ForumDiscussion';
import NouvDiscModal from '../components/Forum/NouvDiscModal';
import useForumPagination from '../hooks/useForumPagination';

const IptvSetup = () => {
    const [showModal, setShowModal] = useState(false);
    const { currentUser } = useAuth();
    const {
        discussions,
        loading,
        hasMore,
        loadMore,
        refreshDiscussions
    } = useForumPagination('iptv-setup-discussions');

    return (
        <main className="iptv-setup-page">
            <Helmet>
                <title>Configuration et Installation IPTV - Guide Complet - Tech Passion</title>
                <meta name="description" content="Guide étape par étape pour configurer et installer votre IPTV : paramétrage des appareils, configuration des applications, résolution des problèmes courants et optimisation de la qualité de streaming." />
                <link rel="canonical" href="https://techpassion.fr/configuration-installation-iptv" />
                <meta name="keywords" content="configuration IPTV, installation IPTV, paramétrage IPTV, guide IPTV, tutoriel IPTV, setup IPTV, optimisation IPTV, dépannage IPTV" />
                <meta property="og:title" content="Configuration et Installation IPTV - Guide Complet - Tech Passion" />
                <meta property="og:description" content="Apprenez à configurer et installer votre IPTV : guide détaillé avec tutoriels et conseils pour une installation réussie." />
                <meta property="og:url" content="https://techpassion.fr/configuration-installation-iptv" />
                <meta property="og:type" content="website" />
            </Helmet>

            <section className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>Configuration et Installation IPTV</h1>
                        <p>Guide complet pour configurer et installer votre service IPTV</p>
                    </div>
                </div>
                <img 
                    className="hero-image"
                    src={heroIptvSetup}
                    srcSet={`${heroIptvSetupMobile} 480w, ${heroIptvSetupTablet} 768w, ${heroIptvSetup} 1024w`}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    alt="Guide d'installation IPTV avec configuration de box TV et paramètres de connexion"
                    loading="lazy"
                />
            </section>

            <section className="discussions-section">
                {loading ? (
                    <div className="loading">Chargement des discussions...</div>
                ) : (
                    <div className="discussions-container">
                        {discussions.map(discussion => (
                            <ForumDiscussion
                                key={discussion.id}
                                discussion={discussion}
                                collectionName="iptv-setup-discussions"
                                onReply={refreshDiscussions}
                            />
                        ))}
                    </div>
                )}

                {hasMore && (
                    <div className="load-more-container">
                        <button 
                            className="load-more-btn"
                            onClick={loadMore}
                        >
                            Voir plus
                        </button>
                    </div>
                )}
            </section>

            {currentUser && (
                <button 
                    className="new-discussion-button" 
                    title="Nouvelle discussion"
                    onClick={() => setShowModal(true)}
                >
                    <FaPlus />
                </button>
            )}

            <NouvDiscModal 
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                forumId="iptv-setup-discussions"
                onDiscussionUpdate={refreshDiscussions}
            />
        </main>
    );
};

export default IptvSetup;
