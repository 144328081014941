import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';

// Désactivation du StrictMode en production pour éviter le double rendu
const root = createRoot(document.getElementById('root'));

// Utilisation de requestIdleCallback pour le chargement non critique
const renderApp = () => {
  root.render(<App />);
};

// Chargement différé des analytics
const loadAnalytics = () => {
  import('./reportWebVitals').then(({ default: reportWebVitals }) => {
    reportWebVitals(console.log);
  });
};

// Démarrage de l'application
if (window.requestIdleCallback) {
  window.requestIdleCallback(loadAnalytics);
} else {
  setTimeout(loadAnalytics, 1000);
}

renderApp();
