import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { FaPlus } from 'react-icons/fa';
import './SmartThermostats.css';
import ForumDiscussion from '../components/Forum/ForumDiscussion';
import NouvDiscModal from '../components/Forum/NouvDiscModal';
import heroSmartThermostats from '../assets/hero-smart-thermostats.jpg';
import heroSmartThermostatsMobile from '../assets/compressed/mobile/hero-smart-thermostats.jpg';
import heroSmartThermostatsTablet from '../assets/compressed/tablet/hero-smart-thermostats.jpg';
import useForumPagination from '../hooks/useForumPagination';

const SmartThermostats = () => {
    const [showModal, setShowModal] = useState(false);
    const { currentUser } = useAuth();
    const {
        discussions,
        loading,
        hasMore,
        loadMore,
        refreshDiscussions
    } = useForumPagination('smart-thermostats-discussions');

    return (
        <main className="smart-thermostats-page">
            <Helmet>
                <title>Thermostats Intelligents - Tech Passion</title>
                <meta name="description" content="Optimisez votre confort et réduisez votre consommation énergétique avec les thermostats intelligents. Découvrez les meilleures solutions, guides d'installation et conseils d'utilisation." />
                <link rel="canonical" href="https://techpassion.fr/thermostats-intelligents" />
                <meta name="keywords" content="thermostats intelligents, smart thermostats, chauffage connecté, économies d'énergie, domotique thermique, contrôle température, maison connectée" />
                <meta property="og:title" content="Thermostats Intelligents - Tech Passion" />
                <meta property="og:description" content="Optimisez votre confort et réduisez votre consommation énergétique avec les thermostats intelligents. Guides et conseils pour une gestion optimale." />
                <meta property="og:url" content="https://techpassion.fr/thermostats-intelligents" />
                <meta property="og:type" content="website" />
            </Helmet>

            <section className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>Thermostats Smart</h1>
                        <p>Découvrez et partagez des conseils sur les thermostats connectés</p>
                    </div>
                </div>
                <img 
                    className="hero-image"
                    src={heroSmartThermostats}
                    srcSet={`${heroSmartThermostatsMobile} 480w, ${heroSmartThermostatsTablet} 768w, ${heroSmartThermostats} 1024w`}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    alt="Thermostat intelligent en situation avec interface tactile et connexion à une application mobile"
                    loading="lazy"
                />
            </section>

            <section className="discussions-section">
                {loading ? (
                    <div className="loading">Chargement des discussions...</div>
                ) : (
                    <div className="discussions-container">
                        {discussions.map(discussion => (
                            <ForumDiscussion
                                key={discussion.id}
                                discussion={discussion}
                                collectionName="smart-thermostats-discussions"
                                onReply={refreshDiscussions}
                            />
                        ))}
                    </div>
                )}

                {hasMore && (
                    <div className="load-more-container">
                        <button 
                            className="load-more-btn"
                            onClick={loadMore}
                        >
                            Voir plus
                        </button>
                    </div>
                )}
            </section>

            {currentUser && (
                <button 
                    className="round-button"
                    onClick={() => setShowModal(true)}
                    title="Nouvelle discussion"
                >
                    <FaPlus size={24} />
                </button>
            )}

            <NouvDiscModal 
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                forumId="smart-thermostats-discussions"
                onDiscussionUpdate={refreshDiscussions}
            />
        </main>
    );
};

export default SmartThermostats;
