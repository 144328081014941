import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { FaPlus } from 'react-icons/fa';
import heroPcBuilding from '../assets/hero-pc-building.jpg';
import heroPcBuildingMobile from '../assets/compressed/mobile/hero-pc-building.jpg';
import heroPcBuildingTablet from '../assets/compressed/tablet/hero-pc-building.jpg';
import './PcBuilding.css';
import ForumDiscussion from '../components/Forum/ForumDiscussion';
import NouvDiscModal from '../components/Forum/NouvDiscModal';
import useForumPagination from '../hooks/useForumPagination';

const PcBuilding = () => {
    const [showModal, setShowModal] = useState(false);
    const { currentUser } = useAuth();
    const {
        discussions,
        loading,
        hasMore,
        loadMore,
        refreshDiscussions
    } = useForumPagination('pc-building-discussions');

    return (
        <main className="pc-building-page">
            <Helmet>
                <title>Guide Assemblage PC - Tech Passion</title>
                <meta name="description" content="Guide complet pour assembler votre PC : choix des composants, étapes de montage, compatibilité matérielle et optimisation. Tutoriels détaillés et conseils d'experts pour construire votre ordinateur sur mesure." />
                <link rel="canonical" href="https://techpassion.fr/assemblage-pc" />
                <meta name="keywords" content="assemblage PC, montage ordinateur, composants PC, guide PC, build PC, compatibilité hardware, construction PC, tutoriel assemblage" />
                <meta property="og:title" content="Guide Assemblage PC - Tech Passion" />
                <meta property="og:description" content="Apprenez à assembler votre PC : guides étape par étape et conseils pour construire l'ordinateur parfait selon vos besoins." />
                <meta property="og:url" content="https://techpassion.fr/assemblage-pc" />
                <meta property="og:type" content="website" />
            </Helmet>

            <section className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>Assemblage PC</h1>
                        <p>Guide complet et conseils pour monter votre PC sur mesure</p>
                    </div>
                </div>
                <img 
                    className="hero-image"
                    src={heroPcBuilding}
                    srcSet={`${heroPcBuildingMobile} 480w, ${heroPcBuildingTablet} 768w, ${heroPcBuilding} 1024w`}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    alt="Montage PC personnalisé avec composants haute performance et guide d'assemblage détaillé"
                    loading="lazy"
                />
            </section>

            <section className="discussions-section">
                {loading ? (
                    <div className="loading">Chargement des discussions...</div>
                ) : (
                    <div className="discussions-container">
                        {discussions.map(discussion => (
                            <ForumDiscussion
                                key={discussion.id}
                                discussion={discussion}
                                collectionName="pc-building-discussions"
                                onReply={refreshDiscussions}
                            />
                        ))}
                    </div>
                )}

                {hasMore && (
                    <div className="load-more-container">
                        <button 
                            className="load-more-btn"
                            onClick={loadMore}
                        >
                            Voir plus
                        </button>
                    </div>
                )}
            </section>

            {currentUser && (
                <button 
                    className="new-discussion-button" 
                    title="Nouvelle discussion"
                    onClick={() => setShowModal(true)}
                >
                    <FaPlus />
                </button>
            )}

            <NouvDiscModal 
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                forumId="pc-building-discussions"
                onDiscussionUpdate={refreshDiscussions}
            />
        </main>
    );
};

export default PcBuilding;
