import React, { createContext, useState, useContext, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useUserProfile } from '../hooks/useUserProfile';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  
  // Utiliser useUserProfile pour obtenir les informations complètes du profil
  const { userProfile } = useUserProfile(currentUser?.uid);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async user => {
      if (user) {
        // Forcer une relecture des données utilisateur
        await user.reload();
        
        // Si l'email n'est pas vérifié, déconnecter l'utilisateur
        if (!user.emailVerified) {
          await auth.signOut();
          setCurrentUser(null);
          return;
        }

        // Si l'utilisateur est connecté et vérifié, mettre à jour ses informations
        setCurrentUser({
          ...user,
          displayName: userProfile?.displayName || user.displayName,
          photoURL: userProfile?.photoURL || user.photoURL,
          role: userProfile?.role,
          badge: userProfile?.badge,
          emailVerified: user.emailVerified
        });

        // Mettre à jour le statut de vérification de l'email dans Firestore
        if (user.emailVerified) {
          const userRef = doc(db, 'users', user.uid);
          await updateDoc(userRef, {
            emailVerified: true
          });
        }
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, [auth, userProfile]);

  const value = {
    currentUser,
    isAuthenticated: !!currentUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
