import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { FaPlus } from 'react-icons/fa';
import heroEssentialApps from '../assets/hero-essential-apps.jpg';
import heroEssentialAppsMobile from '../assets/compressed/mobile/hero-essential-apps.jpg';
import heroEssentialAppsTablet from '../assets/compressed/tablet/hero-essential-apps.jpg';
import './EssentialApps.css';
import ForumDiscussion from '../components/Forum/ForumDiscussion';
import NouvDiscModal from '../components/Forum/NouvDiscModal';
import useForumPagination from '../hooks/useForumPagination';

const EssentialApps = () => {
    const [showModal, setShowModal] = useState(false);
    const { currentUser } = useAuth();
    const {
        discussions,
        loading,
        hasMore,
        loadMore,
        refreshDiscussions
    } = useForumPagination('essential-apps-discussions');

    return (
        <main className="essential-apps-page">
            <Helmet>
                <title>Applications Indispensables pour Smartphone - Tech Passion</title>
                <meta name="description" content="Découvrez les applications mobiles indispensables : productivité, réseaux sociaux, multimédia, sécurité et utilitaires. Sélection des meilleures apps pour iOS et Android avec guides d'installation et astuces." />
                <link rel="canonical" href="https://techpassion.fr/applications-indispensables" />
                <meta name="keywords" content="applications indispensables, meilleures apps, applications mobiles, apps productivité, apps sécurité, apps multimédia, applications Android, applications iOS" />
                <meta property="og:title" content="Applications Indispensables pour Smartphone - Tech Passion" />
                <meta property="og:description" content="Explorez notre sélection d'applications mobiles indispensables : les meilleures apps pour optimiser l'utilisation de votre smartphone." />
                <meta property="og:url" content="https://techpassion.fr/applications-indispensables" />
                <meta property="og:type" content="website" />
            </Helmet>

            <section className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>Applications Indispensables</h1>
                        <p>Découvrez et partagez les applications mobiles essentielles pour votre quotidien</p>
                    </div>
                </div>
                <img 
                    className="hero-image"
                    src={heroEssentialApps}
                    srcSet={`${heroEssentialAppsMobile} 480w, ${heroEssentialAppsTablet} 768w, ${heroEssentialApps} 1024w`}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    alt="Collection d'applications essentielles pour la productivité et le divertissement sur mobile"
                    loading="lazy"
                />
            </section>

            <section className="discussions-section">
                {loading ? (
                    <div className="loading">Chargement des discussions...</div>
                ) : (
                    <div className="discussions-container">
                        {discussions.map(discussion => (
                            <ForumDiscussion
                                key={discussion.id}
                                discussion={discussion}
                                collectionName="essential-apps-discussions"
                                onReply={refreshDiscussions}
                            />
                        ))}
                    </div>
                )}

                {hasMore && (
                    <div className="load-more-container">
                        <button 
                            className="load-more-btn"
                            onClick={loadMore}
                        >
                            Voir plus
                        </button>
                    </div>
                )}
            </section>

            {currentUser && (
                <>
                    <button 
                        className="new-discussion-button"
                        onClick={() => setShowModal(true)}
                        title="Nouvelle discussion"
                    >
                        <FaPlus size={24} />
                    </button>

                    {showModal && (
                        <NouvDiscModal
                            isOpen={showModal}
                            onClose={() => setShowModal(false)}
                            forumId="essential-apps-discussions"
                            onDiscussionUpdate={refreshDiscussions}
                        />
                    )}
                </>
            )}
        </main>
    );
};

export default EssentialApps;
