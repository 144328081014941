import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { FaPlus } from 'react-icons/fa';
import heroSmartGrid from '../assets/hero-smart-grid.jpg';
import heroSmartGridMobile from '../assets/compressed/mobile/hero-smart-grid.jpg';
import heroSmartGridTablet from '../assets/compressed/tablet/hero-smart-grid.jpg';
import './SmartGrid.css';
import ForumDiscussion from '../components/Forum/ForumDiscussion';
import NouvDiscModal from '../components/Forum/NouvDiscModal';
import useForumPagination from '../hooks/useForumPagination';

const SmartGrid = () => {
    const [showModal, setShowModal] = useState(false);
    const { currentUser } = useAuth();
    const {
        discussions,
        loading,
        hasMore,
        loadMore,
        refreshDiscussions
    } = useForumPagination('smart-grid-discussions');

    return (
        <main className="smart-grid-page">
            <Helmet>
                <title>Smart Grid - Tech Passion</title>
                <meta name="description" content="Découvrez les réseaux électriques intelligents : gestion intelligente de l'énergie, intégration des énergies renouvelables et optimisation de la distribution électrique. Guide complet sur les Smart Grids." />
                <link rel="canonical" href="https://techpassion.fr/smart-grid" />
                <meta name="keywords" content="smart grid, réseau électrique intelligent, gestion énergie, distribution électrique, énergies renouvelables, efficacité énergétique, réseau intelligent, innovation énergétique" />
                <meta property="og:title" content="Smart Grid - Tech Passion" />
                <meta property="og:description" content="Explorez les Smart Grids : réseaux électriques nouvelle génération pour une gestion intelligente et durable de l'énergie." />
                <meta property="og:url" content="https://techpassion.fr/smart-grid" />
                <meta property="og:type" content="website" />
            </Helmet>

            <section className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>Smart Grid</h1>
                        <p>Explorez l'avenir des réseaux électriques intelligents et leur impact sur notre consommation d'énergie</p>
                    </div>
                </div>
                <img 
                    className="hero-image"
                    src={heroSmartGrid}
                    srcSet={`${heroSmartGridMobile} 480w, ${heroSmartGridTablet} 768w, ${heroSmartGrid} 1024w`}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    alt="Illustration d'un réseau électrique intelligent avec des connexions et des éléments interactifs représentant la technologie Smart Grid"
                    loading="lazy"
                />
            </section>

            <section className="discussions-section">
                {loading ? (
                    <div className="loading">Chargement des discussions...</div>
                ) : (
                    <div className="discussions-container">
                        {discussions.map(discussion => (
                            <ForumDiscussion
                                key={discussion.id}
                                discussion={discussion}
                                collectionName="smart-grid-discussions"
                                onReply={refreshDiscussions}
                            />
                        ))}
                    </div>
                )}

                {hasMore && (
                    <div className="load-more-container">
                        <button 
                            className="load-more-btn"
                            onClick={loadMore}
                        >
                            Voir plus
                        </button>
                    </div>
                )}
            </section>

            {currentUser && (
                <button 
                    className="round-button"
                    onClick={() => setShowModal(true)}
                    title="Nouvelle discussion"
                >
                    <FaPlus size={24} />
                </button>
            )}

            <NouvDiscModal 
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                forumId="smart-grid-discussions"
                onDiscussionUpdate={refreshDiscussions}
            />
        </main>
    );
};

export default SmartGrid;
