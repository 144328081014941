import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { FaPlus } from 'react-icons/fa';
import heroVRGaming from '../assets/hero-vr-gaming.jpg';
import heroVRGamingMobile from '../assets/compressed/mobile/hero-vr-gaming.jpg';
import heroVRGamingTablet from '../assets/compressed/tablet/hero-vr-gaming.jpg';
import './VRGaming.css';
import ForumDiscussion from '../components/Forum/ForumDiscussion';
import NouvDiscModal from '../components/Forum/NouvDiscModal';
import useForumPagination from '../hooks/useForumPagination';

const VRGaming = () => {
    const [showModal, setShowModal] = useState(false);
    const { currentUser } = useAuth();
    const {
        discussions,
        loading,
        hasMore,
        loadMore,
        refreshDiscussions
    } = useForumPagination('vr-gaming-discussions');

    return (
        <main className="vr-gaming-page">
            <Helmet>
                <title>VR Gaming (Jeux en Réalité Virtuelle) - Tech Passion</title>
                <meta name="description" content="Plongez dans l'univers du Gaming VR : casques de réalité virtuelle, jeux immersifs, technologies VR et expériences de jeu révolutionnaires. Guide complet sur le futur du jeu vidéo en réalité virtuelle." />
                <link rel="canonical" href="https://techpassion.fr/vr-gaming" />
                <meta name="keywords" content="VR gaming, jeux réalité virtuelle, casques VR, gaming immersif, Oculus, PlayStation VR, jeux VR, expérience virtuelle" />
                <meta property="og:title" content="VR Gaming (Jeux en Réalité Virtuelle) - Tech Passion" />
                <meta property="og:description" content="Découvrez le Gaming VR : innovations en réalité virtuelle, expériences immersives et futur du jeu vidéo." />
                <meta property="og:url" content="https://techpassion.fr/vr-gaming" />
                <meta property="og:type" content="website" />
            </Helmet>

            <section className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>VR Gaming</h1>
                        <p>Explorez l'univers immersif des jeux en réalité virtuelle et découvrez les dernières innovations</p>
                    </div>
                </div>
                <img 
                    className="hero-image"
                    src={heroVRGaming}
                    srcSet={`${heroVRGamingMobile} 480w, ${heroVRGamingTablet} 768w, ${heroVRGaming} 1024w`}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    alt="Joueur immergé dans un environnement de jeu en réalité virtuelle avec casque VR et manettes"
                    loading="lazy"
                />
            </section>

            <section className="discussions-section">
                {loading ? (
                    <div className="loading">Chargement des discussions...</div>
                ) : (
                    <div className="discussions-container">
                        {discussions.map(discussion => (
                            <ForumDiscussion
                                key={discussion.id}
                                discussion={discussion}
                                collectionName="vr-gaming-discussions"
                                onReply={refreshDiscussions}
                            />
                        ))}
                    </div>
                )}

                {hasMore && (
                    <div className="load-more-container">
                        <button 
                            className="load-more-btn"
                            onClick={loadMore}
                        >
                            Voir plus
                        </button>
                    </div>
                )}
            </section>

            {currentUser && (
                <button 
                    className="new-discussion-button" 
                    title="Nouvelle discussion"
                    onClick={() => setShowModal(true)}
                >
                    <FaPlus />
                </button>
            )}

            <NouvDiscModal 
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                forumId="vr-gaming-discussions"
                onDiscussionUpdate={refreshDiscussions}
            />
        </main>
    );
};

export default VRGaming;
