import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { FaPlus } from 'react-icons/fa';
import heroMetavers from '../assets/hero-metavers.jpg';
import heroMetaversMobile from '../assets/compressed/mobile/hero-metavers.jpg';
import heroMetaversTablet from '../assets/compressed/tablet/hero-metavers.jpg';
import './Metavers.css';
import ForumDiscussion from '../components/Forum/ForumDiscussion';
import NouvDiscModal from '../components/Forum/NouvDiscModal';
import useForumPagination from '../hooks/useForumPagination';

const Metavers = () => {
    const [showModal, setShowModal] = useState(false);
    const { currentUser } = useAuth();
    const {
        discussions,
        loading,
        hasMore,
        loadMore,
        refreshDiscussions
    } = useForumPagination('metavers-discussions');

    return (
        <main className="metavers-page">
            <Helmet>
                <title>Métavers - Tech Passion</title>
                <meta name="description" content="Explorez le Métavers : mondes virtuels, interactions sociales, économie numérique et expériences immersives. Guide complet sur l'univers du métavers et ses opportunités." />
                <link rel="canonical" href="https://techpassion.fr/metavers" />
                <meta name="keywords" content="métavers, monde virtuel, réalité virtuelle, VR sociale, économie virtuelle, NFT, crypto, web3, expérience immersive" />
                <meta property="og:title" content="Métavers - Tech Passion" />
                <meta property="og:description" content="Découvrez le Métavers : univers virtuels, interactions sociales et nouvelles opportunités dans le monde numérique." />
                <meta property="og:url" content="https://techpassion.fr/metavers" />
                <meta property="og:type" content="website" />
            </Helmet>

            <section className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>Métavers</h1>
                        <p>Plongez dans l'univers des mondes virtuels et découvrez l'avenir de la socialisation numérique</p>
                    </div>
                </div>
                <img 
                    className="hero-image"
                    src={heroMetavers}
                    srcSet={`${heroMetaversMobile} 480w, ${heroMetaversTablet} 768w, ${heroMetavers} 1024w`}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    alt="Univers virtuel du métavers avec des avatars interagissant dans un espace social numérique immersif"
                    loading="lazy"
                />
            </section>

            <section className="discussions-section">
                {loading ? (
                    <div className="loading">Chargement des discussions...</div>
                ) : (
                    <div className="discussions-container">
                        {discussions.map(discussion => (
                            <ForumDiscussion
                                key={discussion.id}
                                discussion={discussion}
                                collectionName="metavers-discussions"
                                onReply={refreshDiscussions}
                            />
                        ))}
                    </div>
                )}

                {hasMore && (
                    <div className="load-more-container">
                        <button 
                            className="load-more-btn"
                            onClick={loadMore}
                        >
                            Voir plus
                        </button>
                    </div>
                )}
            </section>

            {currentUser && (
                <button 
                    className="new-discussion-button" 
                    title="Nouvelle discussion"
                    onClick={() => setShowModal(true)}
                >
                    <FaPlus />
                </button>
            )}

            <NouvDiscModal 
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                forumId="metavers-discussions"
                onDiscussionUpdate={refreshDiscussions}
            />
        </main>
    );
};

export default Metavers;
