import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { FaPlus } from 'react-icons/fa';
import heroBestIptv from '../assets/hero-best-iptv.jpg';
import heroBestIptvMobile from '../assets/compressed/mobile/hero-best-iptv.jpg';
import heroBestIptvTablet from '../assets/compressed/tablet/hero-best-iptv.jpg';
import './BestIptvServices.css';
import ForumDiscussion from '../components/Forum/ForumDiscussion';
import NouvDiscModal from '../components/Forum/NouvDiscModal';
import useForumPagination from '../hooks/useForumPagination';

const BestIptvServices = () => {
    const [showModal, setShowModal] = useState(false);
    const { currentUser } = useAuth();
    const {
        discussions,
        loading,
        hasMore,
        loadMore,
        refreshDiscussions
    } = useForumPagination('best-iptv-discussions');

    return (
        <main className="best-iptv-services-page">
            <Helmet>
                <title>Meilleurs Services IPTV 2024 - Guide et Comparatif - Tech Passion</title>
                <meta name="description" content="Guide complet des meilleurs services IPTV : comparatif, fonctionnalités, qualité de streaming, stabilité des connexions et rapport qualité-prix. Trouvez le service IPTV idéal pour vos besoins." />
                <link rel="canonical" href="https://techpassion.fr/meilleurs-services-iptv" />
                <meta name="keywords" content="meilleurs IPTV, services IPTV, streaming TV, IPTV légal, comparatif IPTV, qualité IPTV, fournisseurs IPTV, abonnement IPTV" />
                <meta property="og:title" content="Meilleurs Services IPTV 2024 - Guide et Comparatif - Tech Passion" />
                <meta property="og:description" content="Découvrez notre sélection des meilleurs services IPTV : comparaison détaillée et conseils pour choisir votre fournisseur IPTV." />
                <meta property="og:url" content="https://techpassion.fr/meilleurs-services-iptv" />
                <meta property="og:type" content="website" />
            </Helmet>

            <section className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>Meilleurs Services IPTV</h1>
                        <p>Découvrez et comparez les services IPTV les plus fiables et performants</p>
                    </div>
                </div>
                <img 
                    className="hero-image"
                    src={heroBestIptv}
                    srcSet={`${heroBestIptvMobile} 480w, ${heroBestIptvTablet} 768w, ${heroBestIptv} 1024w`}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    alt="Interface de streaming IPTV avec grille de programmes et contenu multimédia haute qualité"
                    loading="lazy"
                />
            </section>

            <section className="discussions-section">
                {loading ? (
                    <div className="loading">Chargement des discussions...</div>
                ) : (
                    <div className="discussions-container">
                        {discussions.map(discussion => (
                            <ForumDiscussion
                                key={discussion.id}
                                discussion={discussion}
                                collectionName="best-iptv-discussions"
                                onReply={refreshDiscussions}
                            />
                        ))}
                    </div>
                )}

                {hasMore && (
                    <div className="load-more-container">
                        <button 
                            className="load-more-btn"
                            onClick={loadMore}
                        >
                            Voir plus
                        </button>
                    </div>
                )}
            </section>

            {currentUser && (
                <button 
                    className="new-discussion-button" 
                    title="Nouvelle discussion"
                    onClick={() => setShowModal(true)}
                >
                    <FaPlus />
                </button>
            )}

            <NouvDiscModal 
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                forumId="best-iptv-discussions"
                onDiscussionUpdate={refreshDiscussions}
            />
        </main>
    );
};

export default BestIptvServices;
