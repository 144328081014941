import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { FaPlus } from 'react-icons/fa';
import heroElectricCars from '../assets/hero-electric-cars.jpg';
import heroElectricCarsMobile from '../assets/compressed/mobile/hero-electric-cars.jpg';
import heroElectricCarsTablet from '../assets/compressed/tablet/hero-electric-cars.jpg';
import './ElectricCars.css';
import ForumDiscussion from '../components/Forum/ForumDiscussion';
import NouvDiscModal from '../components/Forum/NouvDiscModal';
import useForumPagination from '../hooks/useForumPagination';

const ElectricCars = () => {
    const [showModal, setShowModal] = useState(false);
    const { currentUser } = useAuth();
    const {
        discussions,
        loading,
        hasMore,
        loadMore,
        refreshDiscussions
    } = useForumPagination('electric-cars-discussions');

    return (
        <main className="electric-cars-page">
            <Helmet>
                <title>Voitures Électriques - Tech Passion</title>
                <meta name="description" content="Découvrez tout sur les voitures électriques : nouveaux modèles, autonomie, recharge, aides à l'achat et conseils pratiques. Guide complet pour choisir et utiliser votre véhicule électrique." />
                <link rel="canonical" href="https://techpassion.fr/voitures-electriques" />
                <meta name="keywords" content="voitures électriques, véhicules électriques, Tesla, recharge électrique, bornes de recharge, autonomie électrique, mobilité verte, écomobilité" />
                <meta property="og:title" content="Voitures Électriques - Tech Passion" />
                <meta property="og:description" content="Découvrez tout sur les voitures électriques : modèles, autonomie, recharge et conseils pratiques pour une mobilité plus verte." />
                <meta property="og:url" content="https://techpassion.fr/voitures-electriques" />
                <meta property="og:type" content="website" />
            </Helmet>

            <section className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>Voitures Électriques</h1>
                        <p>Découvrez l'avenir de la mobilité avec les dernières innovations en matière de voitures électriques</p>
                    </div>
                </div>
                <img 
                    className="hero-image"
                    src={heroElectricCars}
                    srcSet={`${heroElectricCarsMobile} 480w, ${heroElectricCarsTablet} 768w, ${heroElectricCars} 1024w`}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    alt="Voiture électrique moderne avec design aérodynamique et station de recharge rapide intégrée"
                    loading="lazy"
                />
            </section>

            <section className="discussions-section">
                {loading ? (
                    <div className="loading">Chargement des discussions...</div>
                ) : (
                    <div className="discussions-container">
                        {discussions.map(discussion => (
                            <ForumDiscussion
                                key={discussion.id}
                                discussion={discussion}
                                collectionName="electric-cars-discussions"
                                onReply={refreshDiscussions}
                            />
                        ))}
                    </div>
                )}

                {hasMore && (
                    <div className="load-more-container">
                        <button 
                            className="load-more-btn"
                            onClick={loadMore}
                        >
                            Voir plus
                        </button>
                    </div>
                )}
            </section>

            {currentUser && (
                <button 
                    className="new-discussion-button" 
                    title="Nouvelle discussion"
                    onClick={() => setShowModal(true)}
                >
                    <FaPlus />
                </button>
            )}

            <NouvDiscModal 
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                forumId="electric-cars-discussions"
                onDiscussionUpdate={refreshDiscussions}
            />
        </main>
    );
};

export default ElectricCars;
