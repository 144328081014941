import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import heroDev from '../assets/hero-dev.jpg';
import heroDevMobile from '../assets/compressed/mobile/hero-dev.jpg';
import heroDevTablet from '../assets/compressed/tablet/hero-dev.jpg';
import aiIcon from '../assets/icons/ai-icon.svg';
import vrIcon from '../assets/icons/vr-icon.svg';
import quantumIcon from '../assets/icons/quantum-icon.svg';
import blockchainIcon from '../assets/icons/blockchain-icon.svg';
import './Developpement.css';

const Developpement = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.hash) {
            const element = document.querySelector(location.hash);
            if (element) {
                setTimeout(() => {
                    element.scrollIntoView({ behavior: 'smooth' });
                }, 100);
            }
        }
    }, [location]);

    return (
        <main>
            {/* Balises SEO */}
            <Helmet>
                <title>Développement - Tech Passion</title>
                <meta name="description" content="Découvrez les dernières avancées en Intelligence Artificielle, Réalité Virtuelle, Informatique Quantique et Blockchain. Explorez l'avenir du développement." />
                <link rel="canonical" href="https://techpassion.fr/developpement" />
                <meta name="keywords" content="développement, intelligence artificielle, réalité virtuelle, informatique quantique, blockchain, innovation technologique" />
                <meta property="og:title" content="Développement - Tech Passion" />
                <meta property="og:description" content="Découvrez les dernières avancées en Intelligence Artificielle, Réalité Virtuelle, Informatique Quantique et Blockchain." />
                <meta property="og:url" content="https://techpassion.fr/developpement" />
                <meta property="og:type" content="website" />
            </Helmet>

            {/* Hero Section */}
            <section className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>Développement</h1>
                        <p className="hero-subtitle">Explorez l'univers du développement et de l'innovation</p>
                    </div>
                </div>
                <img 
                    className="hero-image" 
                    src={heroDev} 
                    srcSet={`${heroDevMobile} 480w, ${heroDevTablet} 768w, ${heroDev} 1024w`}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    alt="Illustration du développement logiciel et de l'innovation technologique avec des éléments de code et des interfaces modernes"
                    loading="lazy"
                />
            </section>

            <section className="page-content">
                {/* Intelligence Artificielle Section */}
                <div className="category-section" id="intelligence-artificielle">
                    <div className="section-header">
                        <img src={aiIcon} alt="Icône représentant un réseau neuronal avec connexions" className="section-icon" />
                        <div className="section-text">
                            <h2>Intelligence Artificielle</h2>
                            <p>Les avancées majeures en IA et leurs applications</p>
                        </div>
                    </div>
                    <div className="content-grid">
                        <article className="tech-card" onClick={() => navigate('/developpement/machine-learning')}>
                            <h3>Machine Learning</h3>
                            <p>Algorithmes et modèles d'apprentissage</p>
                        </article>
                        <article className="tech-card" onClick={() => navigate('/developpement/deep-learning')}>
                            <h3>Deep Learning</h3>
                            <p>Réseaux de neurones et applications</p>
                        </article>
                        <article className="tech-card" onClick={() => navigate('/developpement/nlp')}>
                            <h3>NLP</h3>
                            <p>Traitement du langage naturel</p>
                        </article>
                        <article className="tech-card" onClick={() => navigate('/developpement/computer-vision')}>
                            <h3>Computer Vision</h3>
                            <p>Vision par ordinateur et reconnaissance d'images</p>
                        </article>
                    </div>
                </div>

                {/* Réalité Virtuelle Section */}
                <div className="category-section" id="realite-virtuelle">
                    <div className="section-header">
                        <img src={vrIcon} alt="Icône représentant un casque de réalité virtuelle" className="section-icon" />
                        <div className="section-text">
                            <h2>Réalité Virtuelle</h2>
                            <p>L'avenir de l'immersion numérique</p>
                        </div>
                    </div>
                    <div className="content-grid">
                        <article className="tech-card" onClick={() => navigate('/developpement/vr-gaming')}>
                            <h3>VR Gaming</h3>
                            <p>Jeux et expériences immersives</p>
                        </article>
                        <article className="tech-card" onClick={() => navigate('/developpement/formation-vr')}>
                            <h3>Formation VR</h3>
                            <p>Applications professionnelles et éducatives</p>
                        </article>
                        <article className="tech-card" onClick={() => navigate('/developpement/realite-augmentee')}>
                            <h3>Réalité Augmentée</h3>
                            <p>AR et applications pratiques</p>
                        </article>
                        <article className="tech-card" onClick={() => navigate('/developpement/metavers')}>
                            <h3>Métavers</h3>
                            <p>Mondes virtuels et socialisation</p>
                        </article>
                    </div>
                </div>

                {/* Quantum Computing Section */}
                <div className="category-section" id="informatique-quantique">
                    <div className="section-header">
                        <img src={quantumIcon} alt="Icône représentant un qubit avec superposition quantique" className="section-icon" />
                        <div className="section-text">
                            <h2>Quantum Computing</h2>
                            <p>La révolution du calcul quantique</p>
                        </div>
                    </div>
                    <div className="content-grid">
                        <article className="tech-card" onClick={() => navigate('/developpement/algorithmes-quantiques')}>
                            <h3>Algorithmes Quantiques</h3>
                            <p>Nouveaux paradigmes de calcul</p>
                        </article>
                        <article className="tech-card" onClick={() => navigate('/developpement/hardware-quantique')}>
                            <h3>Hardware Quantique</h3>
                            <p>Qubits et processeurs quantiques</p>
                        </article>
                        <article className="tech-card" onClick={() => navigate('/developpement/applications')}>
                            <h3>Applications</h3>
                            <p>Applications pratiques du quantique</p>
                        </article>
                        <article className="tech-card" onClick={() => navigate('/developpement/cryptographie')}>
                            <h3>Cryptographie</h3>
                            <p>Sécurité post-quantique</p>
                        </article>
                    </div>
                </div>

                {/* Blockchain Section */}
                <div className="category-section" id="blockchain">
                    <div className="section-header">
                        <img src={blockchainIcon} alt="Icône représentant une chaîne de blocs interconnectés" className="section-icon" />
                        <div className="section-text">
                            <h2>Blockchain</h2>
                            <p>Technologies décentralisées et Web3</p>
                        </div>
                    </div>
                    <div className="content-grid">
                        <article className="tech-card" onClick={() => navigate('/developpement/smart-contracts')}>
                            <h3>Smart Contracts</h3>
                            <p>Automatisation et sécurité</p>
                        </article>
                        <article className="tech-card" onClick={() => navigate('/developpement/defi')}>
                            <h3>DeFi</h3>
                            <p>Finance décentralisée</p>
                        </article>
                        <article className="tech-card" onClick={() => navigate('/developpement/bitcoin')}>
                            <h3>Bitcoin</h3>
                            <p>Actualités, conseils et discussions.</p>
                        </article>
                        <article className="tech-card" onClick={() => navigate('/developpement/altcoins')}>
                            <h3>Altcoins</h3>
                            <p>Explorez l'univers des Altcoins</p>
                        </article>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default Developpement;
