import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { FaPlus } from 'react-icons/fa';
import heroVoiceAssistants from '../assets/hero-voice-assistants.jpg';
import heroVoiceAssistantsMobile from '../assets/compressed/mobile/hero-voice-assistants.jpg';
import heroVoiceAssistantsTablet from '../assets/compressed/tablet/hero-voice-assistants.jpg';
import './VoiceAssistants.css';
import ForumDiscussion from '../components/Forum/ForumDiscussion';
import NouvDiscModal from '../components/Forum/NouvDiscModal';
import useForumPagination from '../hooks/useForumPagination';

const VoiceAssistants = () => {
    const [showModal, setShowModal] = useState(false);
    const { currentUser } = useAuth();
    const {
        discussions,
        loading,
        hasMore,
        loadMore,
        refreshDiscussions
    } = useForumPagination('voice-assistants-discussions');

    return (
        <main className="voice-assistants-page">
            <Helmet>
                <title>Assistants Vocaux - Tech Passion</title>
                <meta name="description" content="Explorez l'univers des assistants vocaux intelligents. Découvrez les comparatifs, guides d'utilisation et astuces pour tirer le meilleur parti d'Alexa, Google Assistant, Siri et autres." />
                <link rel="canonical" href="https://techpassion.fr/assistants-vocaux" />
                <meta name="keywords" content="assistants vocaux, Alexa, Google Assistant, Siri, commande vocale, domotique vocale, maison connectée, IA vocale" />
                <meta property="og:title" content="Assistants Vocaux - Tech Passion" />
                <meta property="og:description" content="Explorez l'univers des assistants vocaux intelligents. Guides et astuces pour maîtriser Alexa, Google Assistant, Siri et autres." />
                <meta property="og:url" content="https://techpassion.fr/assistants-vocaux" />
                <meta property="og:type" content="website" />
            </Helmet>

            <section className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>Assistants Vocaux</h1>
                        <p>Découvrez et échangez sur les assistants vocaux et leur intégration dans la maison connectée</p>
                    </div>
                </div>
                <img 
                    className="hero-image"
                    src={heroVoiceAssistants}
                    srcSet={`${heroVoiceAssistantsMobile} 480w, ${heroVoiceAssistantsTablet} 768w, ${heroVoiceAssistants} 1024w`}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    alt="Assistant vocal intelligent intégré dans un environnement domestique avec interface vocale et contrôle domotique"
                    loading="lazy"
                />
            </section>

            <section className="discussions-section">
                {loading ? (
                    <div className="loading">Chargement des discussions...</div>
                ) : (
                    <div className="discussions-container">
                        {discussions.map(discussion => (
                            <ForumDiscussion
                                key={discussion.id}
                                discussion={discussion}
                                collectionName="voice-assistants-discussions"
                                onReply={refreshDiscussions}
                            />
                        ))}
                    </div>
                )}

                {hasMore && (
                    <div className="load-more-container">
                        <button 
                            className="load-more-btn"
                            onClick={loadMore}
                        >
                            Voir plus
                        </button>
                    </div>
                )}
            </section>

            {currentUser && (
                <button 
                    className="new-discussion-button" 
                    title="Nouvelle discussion"
                    onClick={() => setShowModal(true)}
                >
                    <FaPlus />
                </button>
            )}

            <NouvDiscModal 
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                forumId="voice-assistants-discussions"
                onDiscussionUpdate={refreshDiscussions}
            />
        </main>
    );
};

export default VoiceAssistants;
