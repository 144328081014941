import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { FaPlus } from 'react-icons/fa';
import heroDrones from '../assets/hero-drones.jpg';
import heroDronesMobile from '../assets/compressed/mobile/hero-drones.jpg';
import heroDronesTablet from '../assets/compressed/tablet/hero-drones.jpg';
import './Drones.css';
import ForumDiscussion from '../components/Forum/ForumDiscussion';
import NouvDiscModal from '../components/Forum/NouvDiscModal';
import useForumPagination from '../hooks/useForumPagination';

const Drones = () => {
    const [showModal, setShowModal] = useState(false);
    const { currentUser } = useAuth();
    const {
        discussions,
        loading,
        hasMore,
        loadMore,
        refreshDiscussions
    } = useForumPagination('drones-discussions');

    return (
        <main className="drones-page">
            <Helmet>
                <title>Guide Complet des Drones 2024 - Comparatif et Conseils - Tech Passion</title>
                <meta name="description" content="Tout sur les drones : comparatif des meilleurs modèles, guide d'achat, réglementation, pilotage, photographie aérienne et maintenance. Conseils d'experts pour choisir et utiliser votre drone." />
                <link rel="canonical" href="https://techpassion.fr/drones" />
                <meta name="keywords" content="drones, drone caméra, drone professionnel, drone loisir, pilotage drone, photographie aérienne, réglementation drone, comparatif drones" />
                <meta property="og:title" content="Guide Complet des Drones 2024 - Comparatif et Conseils - Tech Passion" />
                <meta property="og:description" content="Découvrez notre guide complet sur les drones : comparatifs, conseils d'achat et guides pratiques pour choisir et piloter votre drone." />
                <meta property="og:url" content="https://techpassion.fr/drones" />
                <meta property="og:type" content="website" />
            </Helmet>

            <section className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>Drones</h1>
                        <p>Discussions sur les drones, pilotage, réglementation et conseils d'achat</p>
                    </div>
                </div>
                <img 
                    className="hero-image"
                    src={heroDrones}
                    srcSet={`${heroDronesMobile} 480w, ${heroDronesTablet} 768w, ${heroDrones} 1024w`}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    alt="Drone professionnel avec caméra 4K, stabilisation et fonctionnalités de vol autonome"
                    loading="lazy"
                />
            </section>

            <section className="discussions-section">
                {loading ? (
                    <div className="loading">Chargement des discussions...</div>
                ) : (
                    <div className="discussions-container">
                        {discussions.map(discussion => (
                            <ForumDiscussion
                                key={discussion.id}
                                discussion={discussion}
                                collectionName="drones-discussions"
                                onReply={refreshDiscussions}
                            />
                        ))}
                    </div>
                )}

                {hasMore && (
                    <div className="load-more-container">
                        <button 
                            className="load-more-btn"
                            onClick={loadMore}
                        >
                            Voir plus
                        </button>
                    </div>
                )}
            </section>

            {currentUser && (
                <button 
                    className="new-discussion-button" 
                    title="Nouvelle discussion"
                    onClick={() => setShowModal(true)}
                >
                    <FaPlus />
                </button>
            )}

            <NouvDiscModal 
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                forumId="drones-discussions"
                onDiscussionUpdate={refreshDiscussions}
            />
        </main>
    );
};

export default Drones;
