import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { FaPlus } from 'react-icons/fa';
import heroTroubleshootingPc from '../assets/hero-troubleshooting-pc.jpg';
import heroTroubleshootingPcMobile from '../assets/compressed/mobile/hero-troubleshooting-pc.jpg';
import heroTroubleshootingPcTablet from '../assets/compressed/tablet/hero-troubleshooting-pc.jpg';
import './TroubleshootingPc.css';
import ForumDiscussion from '../components/Forum/ForumDiscussion';
import NouvDiscModal from '../components/Forum/NouvDiscModal';
import useForumPagination from '../hooks/useForumPagination';

const TroubleshootingPc = () => {
    const [showModal, setShowModal] = useState(false);
    const { currentUser } = useAuth();
    const {
        discussions,
        loading,
        hasMore,
        loadMore,
        refreshDiscussions
    } = useForumPagination('troubleshooting-pc-discussions');

    return (
        <main className="troubleshooting-pc-page">
            <Helmet>
                <title>Problèmes & Solutions Informatiques - Tech Passion</title>
                <meta name="description" content="Solutions aux problèmes informatiques courants : dépannage PC, résolution d'erreurs Windows, problèmes de performances, sécurité et maintenance. Guide complet de dépannage avec tutoriels détaillés." />
                <link rel="canonical" href="https://techpassion.fr/problemes-solutions" />
                <meta name="keywords" content="dépannage PC, problèmes informatiques, résolution erreurs, maintenance PC, optimisation Windows, réparation ordinateur, solutions techniques, tutoriels dépannage" />
                <meta property="og:title" content="Problèmes & Solutions Informatiques - Tech Passion" />
                <meta property="og:description" content="Trouvez des solutions à vos problèmes informatiques : guides de dépannage PC et tutoriels pour résoudre les erreurs courantes." />
                <meta property="og:url" content="https://techpassion.fr/problemes-solutions" />
                <meta property="og:type" content="website" />
            </Helmet>

            <section className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>Résolution de Problèmes et Dépannage</h1>
                        <p>Solutions aux problèmes courants et guide de maintenance pour votre ordinateur</p>
                    </div>
                </div>
                <img 
                    className="hero-image"
                    src={heroTroubleshootingPc}
                    srcSet={`${heroTroubleshootingPcMobile} 480w, ${heroTroubleshootingPcTablet} 768w, ${heroTroubleshootingPc} 1024w`}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    alt="Illustration du dépannage informatique montrant des outils de diagnostic et des composants d'ordinateur"
                    loading="lazy"
                />
            </section>

            <section className="discussions-section">
                {loading ? (
                    <div className="loading">Chargement des discussions...</div>
                ) : (
                    <div className="discussions-container">
                        {discussions.map(discussion => (
                            <ForumDiscussion
                                key={discussion.id}
                                discussion={discussion}
                                collectionName="troubleshooting-pc-discussions"
                                onReply={refreshDiscussions}
                            />
                        ))}
                    </div>
                )}

                {hasMore && (
                    <div className="load-more-container">
                        <button 
                            className="load-more-btn"
                            onClick={loadMore}
                        >
                            Voir plus
                        </button>
                    </div>
                )}
            </section>

            {currentUser && (
                <button 
                    className="new-discussion-button" 
                    title="Nouvelle discussion"
                    onClick={() => setShowModal(true)}
                >
                    <FaPlus />
                </button>
            )}

            <NouvDiscModal 
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                forumId="troubleshooting-pc-discussions"
                onDiscussionUpdate={refreshDiscussions}
            />
        </main>
    );
};

export default TroubleshootingPc;
