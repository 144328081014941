import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { FaPlus } from 'react-icons/fa';
import heroSmartLighting from '../assets/hero-smart-lighting.jpg';
import heroSmartLightingMobile from '../assets/compressed/mobile/hero-smart-lighting.jpg';
import heroSmartLightingTablet from '../assets/compressed/tablet/hero-smart-lighting.jpg';
import './SmartLighting.css';
import ForumDiscussion from '../components/Forum/ForumDiscussion';
import NouvDiscModal from '../components/Forum/NouvDiscModal';
import useForumPagination from '../hooks/useForumPagination';

const SmartLighting = () => {
    const [showModal, setShowModal] = useState(false);
    const { currentUser } = useAuth();
    const {
        discussions,
        loading,
        hasMore,
        loadMore,
        refreshDiscussions
    } = useForumPagination('smart-lighting-discussions');

    return (
        <main className="smart-lighting-page">
            <Helmet>
                <title>Éclairage Intelligent - Tech Passion</title>
                <meta name="description" content="Découvrez les solutions d'éclairage intelligent pour votre maison connectée. Guides d'installation, comparatifs des meilleures ampoules connectées et conseils pour optimiser votre éclairage." />
                <link rel="canonical" href="https://techpassion.fr/eclairage-intelligent" />
                <meta name="keywords" content="éclairage intelligent, ampoules connectées, smart lighting, domotique, maison connectée, LED intelligentes, automatisation éclairage" />
                <meta property="og:title" content="Éclairage Intelligent - Tech Passion" />
                <meta property="og:description" content="Découvrez les solutions d'éclairage intelligent pour votre maison connectée. Guides et conseils pour un éclairage optimal." />
                <meta property="og:url" content="https://techpassion.fr/eclairage-intelligent" />
                <meta property="og:type" content="website" />
            </Helmet>

            <section className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>Éclairage Intelligent</h1>
                        <p>Découvrez et partagez des conseils sur l'éclairage connecté</p>
                    </div>
                </div>
                <img 
                    className="hero-image"
                    src={heroSmartLighting}
                    srcSet={`${heroSmartLightingMobile} 480w, ${heroSmartLightingTablet} 768w, ${heroSmartLighting} 1024w`}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    alt="Système d'éclairage intelligent avec ampoules connectées et contrôle via smartphone montrant différents scénarios d'éclairage"
                    loading="lazy"
                />
            </section>

            <section className="discussions-section">
                {loading ? (
                    <div className="loading">Chargement des discussions...</div>
                ) : (
                    <div className="discussions-container">
                        {discussions.map(discussion => (
                            <ForumDiscussion
                                key={discussion.id}
                                discussion={discussion}
                                collectionName="smart-lighting-discussions"
                                onReply={refreshDiscussions}
                            />
                        ))}
                    </div>
                )}

                {hasMore && (
                    <div className="load-more-container">
                        <button 
                            className="load-more-btn"
                            onClick={loadMore}
                        >
                            Voir plus
                        </button>
                    </div>
                )}
            </section>

            {currentUser && (
                <button 
                    className="new-discussion-button" 
                    title="Nouvelle discussion"
                    onClick={() => setShowModal(true)}
                >
                    <FaPlus />
                </button>
            )}

            <NouvDiscModal 
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                forumId="smart-lighting-discussions"
                onDiscussionUpdate={refreshDiscussions}
            />
        </main>
    );
};

export default SmartLighting;
