import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { FaPlus } from 'react-icons/fa';
import heroElectricScooters from '../assets/hero-electric-scooters.jpg';
import heroElectricScootersMobile from '../assets/compressed/mobile/hero-electric-scooters.jpg';
import heroElectricScootersTablet from '../assets/compressed/tablet/hero-electric-scooters.jpg';
import './ElectricScooters.css';
import ForumDiscussion from '../components/Forum/ForumDiscussion';
import NouvDiscModal from '../components/Forum/NouvDiscModal';
import useForumPagination from '../hooks/useForumPagination';

const ElectricScooters = () => {
    const [showModal, setShowModal] = useState(false);
    const { currentUser } = useAuth();
    const {
        discussions,
        loading,
        hasMore,
        loadMore,
        refreshDiscussions
    } = useForumPagination('electric-scooters-discussions');

    return (
        <main className="electric-scooters-page">
            <Helmet>
                <title>Trottinettes Électriques - Tech Passion</title>
                <meta name="description" content="Explorez le monde des trottinettes électriques : comparatifs, tests, réglementation et conseils d'achat. Tout ce qu'il faut savoir pour choisir et utiliser votre trottinette électrique en toute sécurité." />
                <link rel="canonical" href="https://techpassion.fr/trottinettes-electriques" />
                <meta name="keywords" content="trottinettes électriques, mobilité urbaine, micromobilité, transport électrique, sécurité routière, autonomie trottinette, réglementation trottinette" />
                <meta property="og:title" content="Trottinettes Électriques - Tech Passion" />
                <meta property="og:description" content="Découvrez tout sur les trottinettes électriques : comparatifs, tests et conseils pour une mobilité urbaine intelligente et sécurisée." />
                <meta property="og:url" content="https://techpassion.fr/trottinettes-electriques" />
                <meta property="og:type" content="website" />
            </Helmet>

            <section className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>Trottinettes Électriques</h1>
                        <p>Explorez la mobilité urbaine du futur avec les trottinettes électriques</p>
                    </div>
                </div>
                <img 
                    className="hero-image"
                    src={heroElectricScooters}
                    srcSet={`${heroElectricScootersMobile} 480w, ${heroElectricScootersTablet} 768w, ${heroElectricScooters} 1024w`}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    alt="Trottinette électrique urbaine avec système de pliage intelligent et écran de contrôle digital"
                    loading="lazy"
                />
            </section>

            <section className="discussions-section">
                {loading ? (
                    <div className="loading">Chargement des discussions...</div>
                ) : (
                    <div className="discussions-container">
                        {discussions.map(discussion => (
                            <ForumDiscussion
                                key={discussion.id}
                                discussion={discussion}
                                collectionName="electric-scooters-discussions"
                                onReply={refreshDiscussions}
                            />
                        ))}
                    </div>
                )}

                {hasMore && (
                    <div className="load-more-container">
                        <button 
                            className="load-more-btn"
                            onClick={loadMore}
                        >
                            Voir plus
                        </button>
                    </div>
                )}
            </section>

            {currentUser && (
                <button 
                    className="new-discussion-button" 
                    title="Nouvelle discussion"
                    onClick={() => setShowModal(true)}
                >
                    <FaPlus />
                </button>
            )}

            <NouvDiscModal 
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                forumId="electric-scooters-discussions"
                onDiscussionUpdate={refreshDiscussions}
            />
        </main>
    );
};

export default ElectricScooters;
