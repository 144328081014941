import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import SearchBar from './SearchBar';
import ProfileSettings from './ProfileSettings';

const Navbar = ({ onThemeToggle, theme, onAuthClick, onSearch }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showProfileSettings, setShowProfileSettings] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const profileMenuRef = useRef(null);
  const profileButtonRef = useRef(null);
  const location = useLocation();
  const { currentUser } = useAuth();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showProfileMenu && 
          profileMenuRef.current && 
          !profileMenuRef.current.contains(event.target) &&
          !profileButtonRef.current.contains(event.target)) {
        setShowProfileMenu(false);
      }
    };

    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        setShowProfileMenu(false);
        setShowMobileMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscape);
    };
  }, [showProfileMenu]);

  // Fermer le menu mobile lors d'un changement de route
  useEffect(() => {
    setShowMobileMenu(false);
  }, [location]);

  const handleAuthClick = async () => {
    if (currentUser) {
      try {
        await signOut(auth);
        window.location.reload(); // Recharge la page après la déconnexion
      } catch (error) {
        console.error('Erreur lors de la déconnexion:', error);
      }
    } else {
      onAuthClick();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(searchQuery);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    onSearch(value);
  };

  const toggleMobileMenu = (e) => {
    e.stopPropagation();
    setShowMobileMenu(!showMobileMenu);
  };

  return (
    <header>
      <nav>
        <div className="nav-left">
          <div className="logo">
            <Link to="/">
              <span className="tech">Tech</span>
              <span className="passion">Passion</span>
            </Link>
          </div>
          <button className="mobile-menu-button" onClick={toggleMobileMenu}>
            ☰
          </button>
          <ul className={showMobileMenu ? 'show' : ''}>
            <li>
              <Link to="/" className={location.pathname === '/' ? 'active' : ''}>
                Accueil
              </Link>
            </li>
            <li>
              <Link 
                to="/high-tech" 
                className={location.pathname === '/high-tech' ? 'active' : ''}
              >
                High-Tech
              </Link>
            </li>
            <li>
              <Link 
                to="/developpement"
                className={location.pathname === '/developpement' ? 'active' : ''}
              >
                Développement
              </Link>
            </li>
            <li>
              <Link 
                to="/tech-gadgets"
                className={location.pathname === '/tech-gadgets' ? 'active' : ''}
              >
                Tech & Gadgets
              </Link>
            </li>
            <li>
              <Link 
                to="/contact"
                className={location.pathname === '/contact' ? 'active' : ''}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
        <div className="nav-right">
          <SearchBar 
            value={searchQuery}
            onChange={handleSearchChange}
            onSubmit={handleSubmit}
          />
          <button 
            className="theme-toggle"
            onClick={onThemeToggle}
          >
            {theme === 'light' ? '🌙' : '☀️'}
          </button>
          {currentUser ? (
            <div className="profile-menu-container">
              <button 
                ref={profileButtonRef}
                className="profile-button"
                onClick={() => setShowProfileMenu(!showProfileMenu)}
              >
                <img 
                  src={currentUser.photoURL}
                  alt="Avatar"
                  className="profile-avatar"
                />
              </button>
              {showProfileMenu && (
                <div 
                  ref={profileMenuRef}
                  className="profile-dropdown"
                >
                  <div className="profile-info">
                    <img 
                      src={currentUser.photoURL}
                      alt="Avatar"
                      className="profile-avatar-large"
                    />
                    <div className="profile-details">
                      <span className="profile-name">{currentUser.displayName}</span>
                      <span className="profile-email">{currentUser.email}</span>
                      {currentUser.badge && (
                        <span className={`badge ${currentUser.badge.type}`}>
                          {currentUser.badge.label}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="profile-menu-items">
                    <button onClick={() => {
                      setShowProfileSettings(true);
                      setShowProfileMenu(false);
                    }}>
                      Gérer Mon Profil
                    </button>
                    <button onClick={handleAuthClick}>
                      Se déconnecter
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <button 
              className="auth-btn"
              onClick={handleAuthClick}
            >
              Se Connecter
            </button>
          )}
        </div>
      </nav>
      {showProfileSettings && (
        <ProfileSettings onClose={() => setShowProfileSettings(false)} />
      )}
    </header>
  );
};

export default Navbar;
