import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async'; // Import du HelmetProvider
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import AuthModal from './components/AuthModal';
import Home from './pages/Home';
import HighTech from './pages/HighTech';
import Developpement from './pages/Developpement';
import TechGadgets from './pages/TechGadgets';
import Contact from './pages/Contact';
import MentionsLegales from './pages/MentionsLegales';
import SmartLighting from './pages/SmartLighting';
import ConnectedSecurity from './pages/ConnectedSecurity';
import SmartThermostats from './pages/SmartThermostats';
import VoiceAssistants from './pages/VoiceAssistants';
import ElectricCars from './pages/ElectricCars';
import ElectricScooters from './pages/ElectricScooters';
import ElectricBikes from './pages/ElectricBikes';
import ChargingStations from './pages/ChargingStations';
import AIAntivirus from './pages/AIAntivirus';
import NextGenVPN from './pages/NextGenVPN';
import BiometricAuth from './pages/BiometricAuth';
import CloudProtection from './pages/CloudProtection';
import SolarEnergy from './pages/SolarEnergy';
import TechRecycling from './pages/TechRecycling';
import EcoDesign from './pages/EcoDesign';
import SmartGrid from './pages/SmartGrid';
import MachineLearning from './pages/MachineLearning';
import DeepLearning from './pages/DeepLearning';
import NLP from './pages/NLP';
import ComputerVision from './pages/ComputerVision';
import VRGaming from './pages/VRGaming';
import FormationVR from './pages/FormationVR';
import RealiteAugmentee from './pages/RealiteAugmentee';
import Metavers from './pages/Metavers';
import AlgorithmesQuantiques from './pages/AlgorithmesQuantiques';
import HardwareQuantique from './pages/HardwareQuantique';
import Applications from './pages/Applications';
import Cryptographie from './pages/Cryptographie';
import SmartContracts from './pages/SmartContracts';
import DeFi from './pages/DeFi';
import Bitcoin from './pages/Bitcoin';
import Altcoins from './pages/Altcoins';
import LatestModels from './pages/LatestModels';
import IosVsAndroid from './pages/IosVsAndroid';
import EssentialApps from './pages/EssentialApps';
import TechnicalSupport from './pages/TechnicalSupport';
import PcBuilding from './pages/PcBuilding';
import PcGaming from './pages/PcGaming';
import MacosVsWindows from './pages/MacosVsWindows';
import TroubleshootingPc from './pages/TroubleshootingPc';
import BestIptvServices from './pages/BestIptvServices';
import IptvSetup from './pages/IptvSetup';
import IptvTroubleshooting from './pages/IptvTroubleshooting';
import IptvSecurity from './pages/IptvSecurity';
import Cameras from './pages/Cameras';
import Drones from './pages/Drones';
import Watches from './pages/Watches';
import Miscellaneous from './pages/Miscellaneous';
import PolitiqueConfidentialite from './pages/PolitiqueConfidentialite';
import NotFound from './pages/NotFound';
import { AuthProvider } from './contexts/AuthContext';
import { CookieProvider } from './contexts/CookieContext';
import CookieBanner from './components/CookieBanner';
import CookieModal from './components/CookieModal';
import './styles/App.css';
import { initializeSearchIndex } from './services/search/initializeSearchIndex';

// Composant pour gérer le scroll
function ScrollToTop() {
  const location = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

// Composant wrapper pour gérer la fermeture du modal lors des changements de route
const AppContent = ({ theme, toggleTheme, isAuthModalOpen, setIsAuthModalOpen }) => {
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const initSearch = async () => {
      try {
        await initializeSearchIndex();
      } catch (error) {
        // Gérer silencieusement l'erreur d'initialisation de la recherche
        console.error('Erreur lors de l\'initialisation de la recherche', error);
      }
    };

    // Forcer une réinitialisation
    initSearch();
  }, []);

  useEffect(() => {
    setIsAuthModalOpen(false);
  }, [location, setIsAuthModalOpen]);

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  return (
    <>
      <Navbar 
        onThemeToggle={toggleTheme} 
        theme={theme}
        onAuthClick={() => setIsAuthModalOpen(true)}
        onSearch={handleSearch}
      />
      
      <AuthModal 
        isOpen={isAuthModalOpen}
        onClose={() => setIsAuthModalOpen(false)}
      />
      <Routes>
        <Route path="/" element={<Home searchQuery={searchQuery} />} />
        <Route path="/mentions-legales" element={<MentionsLegales />} />
        <Route path="/politique-confidentialite" element={<PolitiqueConfidentialite />} />
        <Route path="/high-tech" element={<HighTech />} />
        <Route path="/developpement" element={<Developpement />} />
        <Route path="/tech-gadgets" element={<TechGadgets />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/high-tech/smart-lighting" element={<SmartLighting />} />
        <Route path="/high-tech/smart-thermostats" element={<SmartThermostats />} />
        <Route path="/high-tech/voice-assistants" element={<VoiceAssistants />} />
        <Route path="/high-tech/voitures-electriques" element={<ElectricCars />} />
        <Route path="/high-tech/trottinettes" element={<ElectricScooters />} />
        <Route path="/high-tech/velos-electriques" element={<ElectricBikes />} />
        <Route path="/high-tech/bornes-recharge" element={<ChargingStations />} />
        <Route path="/high-tech/antivirus-ia" element={<AIAntivirus />} />
        <Route path="/high-tech/vpn-nouvelle-generation" element={<NextGenVPN />} />
        <Route path="/high-tech/authentification-biometrique" element={<BiometricAuth />} />
        <Route path="/high-tech/protection-cloud" element={<CloudProtection />} />
        <Route path="/high-tech/energie-solaire" element={<SolarEnergy />} />
        <Route path="/high-tech/recyclage-tech" element={<TechRecycling />} />
        <Route path="/high-tech/eco-conception" element={<EcoDesign />} />
        <Route path="/high-tech/smart-grid" element={<SmartGrid />} />
        <Route path="/developpement/nlp" element={<NLP />} />
        <Route path="/developpement/computer-vision" element={<ComputerVision />} />
        <Route path="/developpement/vr-gaming" element={<VRGaming />} />
        <Route path="/developpement/formation-vr" element={<FormationVR />} />
        <Route path="/developpement/realite-augmentee" element={<RealiteAugmentee />} />
        <Route path="/developpement/metavers" element={<Metavers />} />
        <Route path="/developpement/algorithmes-quantiques" element={<AlgorithmesQuantiques />} />
        <Route path="/developpement/hardware-quantique" element={<HardwareQuantique />} />
        <Route path="/developpement/applications" element={<Applications />} />
        <Route path="/developpement/cryptographie" element={<Cryptographie />} />
        <Route path="/developpement/smart-contracts" element={<SmartContracts />} />
        <Route path="/developpement/defi" element={<DeFi />} />
        <Route path="/developpement/bitcoin" element={<Bitcoin />} />
        <Route path="/developpement/altcoins" element={<Altcoins />} />
        <Route path="/developpement/machine-learning" element={<MachineLearning />} />
        <Route path="/developpement/deep-learning" element={<DeepLearning />} />
        <Route path="/high-tech/connected-security" element={<ConnectedSecurity />} />
        <Route path="/tech-gadgets/derniers-modeles" element={<LatestModels />} />
        <Route path="/tech-gadgets/ios-vs-android" element={<IosVsAndroid />} />
        <Route path="/tech-gadgets/applications-indispensables" element={<EssentialApps />} />
        <Route path="/tech-gadgets/problemes-solutions" element={<TechnicalSupport />} />
        <Route path="/tech-gadgets/assemblage-pc" element={<PcBuilding />} />
        <Route path="/tech-gadgets/pc-gaming" element={<PcGaming />} />
        <Route path="/tech-gadgets/macos-vs-windows" element={<MacosVsWindows />} />
        <Route path="/tech-gadgets/resolution-problemes" element={<TroubleshootingPc />} />
        <Route path="/tech-gadgets/meilleurs-services-iptv" element={<BestIptvServices />} />
        <Route path="/tech-gadgets/configuration-installation-iptv" element={<IptvSetup />} />
        <Route path="/tech-gadgets/problemes-depannage-iptv" element={<IptvTroubleshooting />} />
        <Route path="/tech-gadgets/securite-legalite-iptv" element={<IptvSecurity />} />
        <Route path="/tech-gadgets/cameras" element={<Cameras />} />
        <Route path="/tech-gadgets/drones" element={<Drones />} />
        <Route path="/tech-gadgets/montres" element={<Watches />} />
        <Route path="/tech-gadgets/divers-gadgets" element={<Miscellaneous />} />
        <Route path="*" element={<NotFound />} />
      </Routes>

      <Footer />
    </>
  );
};

function App() {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  return (
    <HelmetProvider>
      <Router>
        <AuthProvider>
          <CookieProvider>
            <div className="app" data-theme={theme}>
              <ScrollToTop />
              <AppContent
                theme={theme}
                toggleTheme={toggleTheme}
                isAuthModalOpen={isAuthModalOpen}
                setIsAuthModalOpen={setIsAuthModalOpen}
              />
            </div>
            <CookieBanner />
            <CookieModal />
          </CookieProvider>
        </AuthProvider>
      </Router>
    </HelmetProvider>
  );
}

export default App;
