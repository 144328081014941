import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { FaPlus } from 'react-icons/fa';
import heroBitcoin from '../assets/hero-bitcoin.jpg';
import heroBitcoinMobile from '../assets/compressed/mobile/hero-bitcoin.jpg';
import heroBitcoinTablet from '../assets/compressed/tablet/hero-bitcoin.jpg';
import './Bitcoin.css';
import ForumDiscussion from '../components/Forum/ForumDiscussion';
import NouvDiscModal from '../components/Forum/NouvDiscModal';
import useForumPagination from '../hooks/useForumPagination';

const Bitcoin = () => {
    const [showModal, setShowModal] = useState(false);
    const { currentUser } = useAuth();
    const {
        discussions,
        loading,
        hasMore,
        loadMore,
        refreshDiscussions
    } = useForumPagination('bitcoin-discussions');

    return (
        <main className="bitcoin-page">
            <Helmet>
                <title>Bitcoin : Cryptomonnaie et Blockchain - Tech Passion</title>
                <meta name="description" content="Découvrez Bitcoin : la première cryptomonnaie, sa technologie blockchain, le minage, les portefeuilles et l'investissement. Guide complet sur l'écosystème Bitcoin et son impact sur la finance." />
                <link rel="canonical" href="https://techpassion.fr/bitcoin" />
                <meta name="keywords" content="bitcoin, cryptomonnaie, blockchain, minage bitcoin, portefeuille bitcoin, investissement crypto, BTC, satoshi nakamoto" />
                <meta property="og:title" content="Bitcoin : Cryptomonnaie et Blockchain - Tech Passion" />
                <meta property="og:description" content="Explorez Bitcoin : première cryptomonnaie mondiale, sa technologie révolutionnaire et son rôle dans la transformation de la finance." />
                <meta property="og:url" content="https://techpassion.fr/bitcoin" />
                <meta property="og:type" content="website" />
            </Helmet>

            <section className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>Bitcoin</h1>
                        <p>Explorez l'univers du Bitcoin, ses actualités, son développement et ses perspectives d'avenir</p>
                    </div>
                </div>
                <img 
                    className="hero-image"
                    src={heroBitcoin}
                    srcSet={`${heroBitcoinMobile} 480w, ${heroBitcoinTablet} 768w, ${heroBitcoin} 1024w`}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    alt="Représentation du Bitcoin avec graphiques de trading et technologie blockchain"
                    loading="lazy"
                />
            </section>

            <section className="discussions-section">
                {loading ? (
                    <div className="loading">Chargement des discussions...</div>
                ) : (
                    <div className="discussions-container">
                        {discussions.map(discussion => (
                            <ForumDiscussion
                                key={discussion.id}
                                discussion={discussion}
                                collectionName="bitcoin-discussions"
                                onReply={refreshDiscussions}
                            />
                        ))}
                    </div>
                )}

                {hasMore && (
                    <div className="load-more-container">
                        <button 
                            className="load-more-btn"
                            onClick={loadMore}
                        >
                            Voir plus
                        </button>
                    </div>
                )}
            </section>

            {currentUser && (
                <button 
                    className="new-discussion-button" 
                    title="Nouvelle discussion"
                    onClick={() => setShowModal(true)}
                >
                    <FaPlus />
                </button>
            )}

            <NouvDiscModal 
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                forumId="bitcoin-discussions"
                onDiscussionUpdate={refreshDiscussions}
            />
        </main>
    );
};

export default Bitcoin;
