import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './RichTextEditor.css';

const RichTextEditor = ({ value, onChange, placeholder, onCancel }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [editorContent, setEditorContent] = useState('');
  const quillRef = React.useRef(null);
  const isInitialMount = React.useRef(true);

  useEffect(() => {
    if (isInitialMount.current || value !== editorContent) {
      if (quillRef.current) {
        const editor = quillRef.current.getEditor();
        const currentSelection = editor.getSelection();
        editor.clipboard.dangerouslyPasteHTML(value || '');
        if (currentSelection) {
          editor.setSelection(currentSelection);
        }
      }
      setEditorContent(value || '');
      isInitialMount.current = false;
    }
  }, [value, editorContent]);

  const handleChange = (newValue) => {
    setEditorContent(newValue);
    onChange(newValue);
  };

  const modules = {
    toolbar: {
      container: [
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        ['link', 'image', 'code-block'],
        ['clean']
      ],
    },
    clipboard: {
      matchVisual: false,
    }
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'color', 'background',
    'align',
    'list',
    'link', 'image',
    'code-block'
  ];

  return (
    <div className={`rich-text-editor ${isFocused ? 'focused' : ''}`}>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={value}
        onChange={handleChange}
        modules={modules}
        formats={formats}
        placeholder={placeholder}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
    </div>
  );
};

export default RichTextEditor;
