import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { FaPlus } from 'react-icons/fa';
import heroTechnicalSupport from '../assets/hero-technical-support.jpg';
import heroTechnicalSupportMobile from '../assets/compressed/mobile/hero-technical-support.jpg';
import heroTechnicalSupportTablet from '../assets/compressed/tablet/hero-technical-support.jpg';
import './TechnicalSupport.css';
import ForumDiscussion from '../components/Forum/ForumDiscussion';
import NouvDiscModal from '../components/Forum/NouvDiscModal';
import useForumPagination from '../hooks/useForumPagination';

const TechnicalSupport = () => {
    const [showModal, setShowModal] = useState(false);
    const { currentUser } = useAuth();
    const {
        discussions,
        loading,
        hasMore,
        loadMore,
        refreshDiscussions
    } = useForumPagination('technical-support-discussions');

    return (
        <main className="technical-support-page">
            <Helmet>
                <title>Problèmes Techniques et Solutions - Tech Passion</title>
                <meta name="description" content="Support technique et solutions pour tous vos problèmes : dépannage matériel, logiciel, réseau, sécurité et maintenance. Guides détaillés et assistance pour résoudre vos problèmes technologiques." />
                <link rel="canonical" href="https://techpassion.fr/support-technique" />
                <meta name="keywords" content="support technique, dépannage informatique, problèmes techniques, solutions IT, maintenance technologique, assistance technique, guide dépannage, résolution problèmes" />
                <meta property="og:title" content="Problèmes Techniques et Solutions - Tech Passion" />
                <meta property="og:description" content="Trouvez des solutions à vos problèmes techniques : guides complets et assistance pour résoudre tous vos problèmes technologiques." />
                <meta property="og:url" content="https://techpassion.fr/support-technique" />
                <meta property="og:type" content="website" />
            </Helmet>

            <section className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>Problèmes Techniques et Solutions</h1>
                        <p>Trouvez des solutions aux problèmes courants de votre smartphone</p>
                    </div>
                </div>
                <img 
                    className="hero-image"
                    src={heroTechnicalSupport}
                    srcSet={`${heroTechnicalSupportMobile} 480w, ${heroTechnicalSupportTablet} 768w, ${heroTechnicalSupport} 1024w`}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    alt="Interface de support technique avec outils de diagnostic à distance et assistance en direct"
                    loading="lazy"
                />
            </section>

            <section className="discussions-section">
                {loading ? (
                    <div className="loading">Chargement des discussions...</div>
                ) : (
                    <div className="discussions-container">
                        {discussions.map(discussion => (
                            <ForumDiscussion
                                key={discussion.id}
                                discussion={discussion}
                                collectionName="technical-support-discussions"
                                onReply={refreshDiscussions}
                            />
                        ))}
                    </div>
                )}

                {hasMore && (
                    <div className="load-more-container">
                        <button 
                            className="load-more-btn"
                            onClick={loadMore}
                        >
                            Voir plus
                        </button>
                    </div>
                )}
            </section>

            {currentUser && (
                <button 
                    className="new-discussion-button" 
                    title="Nouvelle discussion"
                    onClick={() => setShowModal(true)}
                >
                    <FaPlus />
                </button>
            )}

            <NouvDiscModal 
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                forumId="technical-support-discussions"
                onDiscussionUpdate={refreshDiscussions}
            />
        </main>      
    );
};

export default TechnicalSupport;
