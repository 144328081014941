import React, { useState, useEffect, useCallback } from 'react';
import './SvgCaptcha.css';

const SvgCaptcha = ({ onValidate }) => {
  const [captchaText, setCaptchaText] = useState('');
  const [userInput, setUserInput] = useState('');

  // Génère un texte aléatoire pour le captcha
  const generateCaptchaText = useCallback(() => {
    const chars = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz23456789';
    let text = '';
    for (let i = 0; i < 6; i++) {
      text += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return text;
  }, []);

  // Génère des points de contrôle aléatoires pour les courbes
  const generateControlPoints = useCallback((width, height) => {
    const points = [];
    for (let i = 0; i < 6; i++) {
      points.push({
        x: Math.random() * width,
        y: Math.random() * height
      });
    }
    return points;
  }, []);

  // Rafraîchit le captcha
  const refreshCaptcha = useCallback(() => {
    const newText = generateCaptchaText();
    setCaptchaText(newText);
    setUserInput('');
    onValidate(false);
  }, [generateCaptchaText, onValidate]);

  // Vérifie l'entrée de l'utilisateur
  const handleInputChange = useCallback((e) => {
    const input = e.target.value;
    setUserInput(input);
    onValidate(input === captchaText);
  }, [captchaText, onValidate]);

  // Initialise le captcha au chargement
  useEffect(() => {
    refreshCaptcha();
  }, [refreshCaptcha]);

  return (
    <div className="captcha-container">
      <svg width="200" height="60" style={{ background: '#f0f0f0' }}>
        {/* Texte du captcha */}
        {captchaText.split('').map((char, index) => (
          <text
            key={index}
            x={20 + index * 30}
            y={35 + Math.sin(index) * 10}
            fontSize="24"
            fontFamily="Arial"
            transform={`rotate(${Math.random() * 20 - 10}, ${20 + index * 30}, 35)`}
            fill={`hsl(${Math.random() * 360}, 70%, 50%)`}
          >
            {char}
          </text>
        ))}
        
        {/* Lignes de bruit */}
        {[...Array(3)].map((_, index) => {
          const points = generateControlPoints(200, 60);
          return (
            <path
              key={`line-${index}`}
              d={`M ${points[0].x},${points[0].y} 
                  Q ${points[1].x},${points[1].y} 
                    ${points[2].x},${points[2].y}`}
              stroke={`hsl(${Math.random() * 360}, 70%, 50%)`}
              strokeWidth="1"
              fill="none"
            />
          );
        })}
      </svg>

      <div className="captcha-controls">
        <input
          type="text"
          value={userInput}
          onChange={handleInputChange}
          placeholder="Entrez le texte ci-dessus"
          className="captcha-input"
        />
        <button
          type="button"
          onClick={refreshCaptcha}
          className="refresh-button"
        >
          ↻
        </button>
      </div>
    </div>
  );
};

export default SvgCaptcha;
