import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { FaPlus } from 'react-icons/fa';
import heroNextGenVPN from '../assets/hero-nextgen-vpn.jpg';
import heroNextGenVPNMobile from '../assets/compressed/mobile/hero-nextgen-vpn.jpg';
import heroNextGenVPNTablet from '../assets/compressed/tablet/hero-nextgen-vpn.jpg';
import './NextGenVPN.css';
import ForumDiscussion from '../components/Forum/ForumDiscussion';
import NouvDiscModal from '../components/Forum/NouvDiscModal';
import useForumPagination from '../hooks/useForumPagination';

const NextGenVPN = () => {
    const [showModal, setShowModal] = useState(false);
    const { currentUser } = useAuth();
    const {
        discussions,
        loading,
        hasMore,
        loadMore,
        refreshDiscussions
    } = useForumPagination('nextgen-vpn-discussions');

    return (
        <main className="nextgen-vpn-page">
            <Helmet>
                <title>VPN Nouvelle Génération - Tech Passion</title>
                <meta name="description" content="Découvrez les VPN de nouvelle génération : protocoles avancés, sécurité renforcée, vitesses optimisées et fonctionnalités intelligentes. Guide complet sur les solutions VPN modernes pour une confidentialité maximale." />
                <link rel="canonical" href="https://techpassion.fr/vpn-nouvelle-generation" />
                <meta name="keywords" content="VPN nouvelle génération, VPN moderne, protocoles VPN, sécurité VPN, confidentialité internet, chiffrement avancé, WireGuard, protection des données" />
                <meta property="og:title" content="VPN Nouvelle Génération - Tech Passion" />
                <meta property="og:description" content="Explorez les VPN modernes : sécurité renforcée, vitesses optimisées et technologies de pointe pour une protection en ligne optimale." />
                <meta property="og:url" content="https://techpassion.fr/vpn-nouvelle-generation" />
                <meta property="og:type" content="website" />
            </Helmet>

            <section className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>VPN Nouvelle Génération</h1>
                        <p>Découvrez les VPN de nouvelle génération pour une confidentialité et une sécurité optimales</p>
                    </div>
                </div>
                <img 
                    className="hero-image"
                    src={heroNextGenVPN}
                    srcSet={`${heroNextGenVPNMobile} 480w, ${heroNextGenVPNTablet} 768w, ${heroNextGenVPN} 1024w`}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    alt="Réseau VPN nouvelle génération avec cryptage avancé et interface de sélection de serveurs mondiale"
                    loading="lazy"
                />
            </section>

            <section className="discussions-section">
                {loading ? (
                    <div className="loading">Chargement des discussions...</div>
                ) : (
                    <div className="discussions-container">
                        {discussions.map(discussion => (
                            <ForumDiscussion
                                key={discussion.id}
                                discussion={discussion}
                                collectionName="nextgen-vpn-discussions"
                                onReply={refreshDiscussions}
                            />
                        ))}
                    </div>
                )}

                {hasMore && (
                    <div className="load-more-container">
                        <button 
                            className="load-more-btn"
                            onClick={loadMore}
                        >
                            Voir plus
                        </button>
                    </div>
                )}
            </section>

            {currentUser && (
                <button 
                    className="new-discussion-button" 
                    title="Nouvelle discussion"
                    onClick={() => setShowModal(true)}
                >
                    <FaPlus />
                </button>
            )}

            <NouvDiscModal 
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                forumId="nextgen-vpn-discussions"
                onDiscussionUpdate={refreshDiscussions}
            />
        </main>
    );
};

export default NextGenVPN;
