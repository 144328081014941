import React, { useState } from 'react';
import { 
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  updateProfile
} from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import AvatarSelector from './AvatarSelector';
import SvgCaptcha from './SvgCaptcha';
import '../styles/AuthModal.css';

const AuthModalContent = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [username, setUsername] = useState('');
  const [isLoginForm, setIsLoginForm] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState('');
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);

  const handleClose = () => {
    setError('');
    setSuccess('');
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setUsername('');
    setIsLoginForm(true);
    setIsForgotPassword(false);
    setIsCaptchaValid(false);
    onClose();
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log('handleLogin called', { email, password });
    
    if (!email || !password) {
      console.log('Missing fields');
      setError('Veuillez remplir tous les champs');
      return;
    }
    
    setError('');
    setIsLoading(true);

    try {
      console.log('Tentative de connexion avec:', { email });
      console.log('Calling signInWithEmailAndPassword');
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      
      // Vérifier si l'email est vérifié
      if (!userCredential.user.emailVerified) {
        await auth.signOut();
        setError('Veuillez vérifier votre email avant de vous connecter. Un nouveau lien de vérification a été envoyé.');
        await sendEmailVerification(userCredential.user);
        setIsLoading(false);
        return;
      }

      console.log('Connexion réussie:', userCredential.user.email);
      setIsLoading(false);
      handleClose();
    } catch (err) {
      console.error('Login error:', err);
      let errorMessage = 'Échec de la connexion';
      switch (err.code) {
        case 'auth/user-not-found':
          errorMessage = 'Aucun compte ne correspond à cet email';
          break;
        case 'auth/wrong-password':
        case 'auth/invalid-credential':
          errorMessage = 'Identifiants incorrects';
          break;
        case 'auth/too-many-requests':
          errorMessage = 'Trop de tentatives. Réessayez plus tard';
          break;
        case 'auth/invalid-email':
          errorMessage = 'Format d\'email invalide';
          break;
        default:
          errorMessage = err.message;
      }
      setError(errorMessage);
      setIsLoading(false);
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    if (!email || !password || !username || !confirmPassword) {
      setError('Veuillez remplir tous les champs');
      return;
    }

    if (password !== confirmPassword) {
      setError('Les mots de passe ne correspondent pas');
      return;
    }

    if (password.length < 6) {
      setError('Le mot de passe doit contenir au moins 6 caractères');
      return;
    }

    if (!isCaptchaValid) {
      setError('Veuillez compléter le captcha correctement');
      return;
    }

    setError('');
    setIsLoading(true);

    try {
      console.log('Tentative d\'inscription avec:', { email, username });
      
      // Créer l'utilisateur
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      console.log('Inscription réussie:', userCredential.user.email);
      
      // Mettre à jour le profil
      await updateProfile(userCredential.user, {
        displayName: username,
        photoURL: selectedAvatar || '/default-avatar.png'
      });

      // Créer le document utilisateur dans Firestore
      try {
        await setDoc(doc(db, 'users', userCredential.user.uid), {
          id: userCredential.user.uid,
          email: email,
          displayName: username,
          photoURL: selectedAvatar || '/default-avatar.png',
          role: 'user',
          createdAt: new Date().toISOString(),
          emailVerified: false
        });
        console.log('Document utilisateur créé avec succès');
      } catch (firestoreError) {
        console.error('Erreur lors de la création du document utilisateur:', firestoreError);
        // Ne pas throw l'erreur ici, continuer le processus
      }

      // Envoyer l'email de vérification
      await sendEmailVerification(userCredential.user);
      
      setSuccess('Inscription réussie ! Un email de vérification a été envoyé à votre adresse email.');
      setIsLoading(false);
      
      // Attendre un peu avant de fermer le modal et rafraîchir
      setTimeout(() => {
        handleClose();
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error('Signup error:', error);
      let errorMessage = 'Échec de l\'inscription';
      switch (error.code) {
        case 'auth/email-already-in-use':
          errorMessage = 'Cet email est déjà utilisé';
          break;
        case 'auth/invalid-email':
          errorMessage = 'Format d\'email invalide';
          break;
        case 'auth/weak-password':
          errorMessage = 'Le mot de passe est trop faible (minimum 6 caractères)';
          break;
        default:
          errorMessage = error.message;
      }
      setError(errorMessage);
      setIsLoading(false);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    
    if (!email) {
      setError('Veuillez entrer votre email');
      return;
    }
    
    setError('');
    setIsLoading(true);

    try {
      await sendPasswordResetEmail(auth, email);
      setSuccess('Email de réinitialisation envoyé !');
      setTimeout(() => {
        setIsForgotPassword(false);
      }, 2000);
    } catch (err) {
      console.error('Password reset error:', err);
      setError('Erreur lors de l\'envoi de l\'email de réinitialisation');
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="auth-modal-overlay">
      <div className="auth-modal-content">
        <button className="auth-modal-close-button" onClick={handleClose}>×</button>
        
        <h2 className="auth-modal-title">
          {isForgotPassword 
            ? 'Réinitialisation du mot de passe'
            : isLoginForm 
              ? 'Connexion' 
              : 'Inscription'}
        </h2>
        
        {error && <div className="auth-modal-error-message">{error}</div>}
        {success && <div className="auth-modal-success-message">{success}</div>}
        
        <form 
          onSubmit={(e) => {
            console.log('Form submitted', { isLoginForm, isForgotPassword });
            if (isForgotPassword) {
              handleForgotPassword(e);
            } else if (isLoginForm) {
              handleLogin(e);
            } else {
              handleSignUp(e);
            }
          }}
          className="auth-modal-form"
        >
          <div className="auth-modal-form-group">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="auth-modal-form-input"
              required
            />
          </div>
          
          {!isForgotPassword && (
            <div className="auth-modal-form-group">
              <input
                type="password"
                placeholder="Mot de passe"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="auth-modal-form-input"
                required
              />
            </div>
          )}
          
          {!isLoginForm && !isForgotPassword && (
            <>
              <div className="auth-modal-form-group">
                <input
                  type="password"
                  placeholder="Confirmer le mot de passe"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="auth-modal-form-input"
                  required
                />
              </div>
              <div className="auth-modal-form-group">
                <input
                  type="text"
                  placeholder="Nom d'utilisateur"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="auth-modal-form-input"
                  required
                />
              </div>
              <AvatarSelector
                selectedAvatar={selectedAvatar}
                onSelect={setSelectedAvatar}
              />
              <SvgCaptcha onValidate={setIsCaptchaValid} />
            </>
          )}
          
          <button
            type="submit"
            className="auth-modal-submit-btn"
            disabled={isLoading || (!isLoginForm && !isForgotPassword && !isCaptchaValid)}
          >
            {isLoading ? 'Chargement...' : 
              isForgotPassword ? 'Envoyer le lien' :
                isLoginForm ? 'Se connecter' : 'S\'inscrire'}
          </button>
        </form>
        
        <div className="auth-modal-footer">
          {!isForgotPassword && (
            <button
              className="auth-modal-link-button"
              onClick={() => setIsLoginForm(!isLoginForm)}
            >
              {isLoginForm ? 'Créer un compte' : 'Déjà inscrit ?'}
            </button>
          )}
          
          {isLoginForm && !isForgotPassword && (
            <button
              className="auth-modal-link-button"
              onClick={() => setIsForgotPassword(true)}
            >
              Mot de passe oublié ?
            </button>
          )}
          
          {isForgotPassword && (
            <button
              className="auth-modal-link-button"
              onClick={() => setIsForgotPassword(false)}
            >
              Retour à la connexion
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const AuthModal = (props) => {
  return <AuthModalContent {...props} />;
};

export default AuthModal;
