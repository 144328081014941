import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import { FaPlus } from 'react-icons/fa';
import heroNLP from '../assets/hero-nlp.jpg';
import heroNLPMobile from '../assets/compressed/mobile/hero-nlp.jpg';
import heroNLPTablet from '../assets/compressed/tablet/hero-nlp.jpg';
import './NLP.css';
import ForumDiscussion from '../components/Forum/ForumDiscussion';
import NouvDiscModal from '../components/Forum/NouvDiscModal';
import useForumPagination from '../hooks/useForumPagination';

const NLP = () => {
    const [showModal, setShowModal] = useState(false);
    const { currentUser } = useAuth();
    const {
        discussions,
        loading,
        hasMore,
        loadMore,
        refreshDiscussions
    } = useForumPagination('nlp-discussions');

    return (
        <main className="nlp-page">
            <Helmet>
                <title>NLP (Traitement du Langage Naturel) - Tech Passion</title>
                <meta name="description" content="Explorez le Traitement du Langage Naturel (NLP) : analyse sémantique, reconnaissance vocale, traduction automatique et compréhension du langage. Guide complet sur les technologies NLP et leurs applications." />
                <link rel="canonical" href="https://techpassion.fr/nlp" />
                <meta name="keywords" content="NLP, traitement langage naturel, natural language processing, analyse sémantique, reconnaissance vocale, traduction automatique, IA linguistique, compréhension langage" />
                <meta property="og:title" content="NLP (Traitement du Langage Naturel) - Tech Passion" />
                <meta property="og:description" content="Découvrez le NLP : technologies de traitement du langage, innovations en reconnaissance vocale et applications en intelligence artificielle." />
                <meta property="og:url" content="https://techpassion.fr/nlp" />
                <meta property="og:type" content="website" />
            </Helmet>

            <section className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>NLP</h1>
                        <p>Découvrez le traitement automatique du langage naturel et son impact sur l'intelligence artificielle moderne</p>
                    </div>
                </div>
                <img 
                    className="hero-image"
                    src={heroNLP}
                    srcSet={`${heroNLPMobile} 480w, ${heroNLPTablet} 768w, ${heroNLP} 1024w`}
                    sizes="(max-width: 768px) 100vw, 50vw"
                    alt="Illustration du traitement du langage naturel montrant l'analyse syntaxique et sémantique du texte"
                    loading="lazy"
                />
            </section>

            <section className="discussions-section">
                {loading ? (
                    <div className="loading">Chargement des discussions...</div>
                ) : (
                    <div className="discussions-container">
                        {discussions.map(discussion => (
                            <ForumDiscussion
                                key={discussion.id}
                                discussion={discussion}
                                collectionName="nlp-discussions"
                                onReply={refreshDiscussions}
                            />
                        ))}
                    </div>
                )}

                {hasMore && (
                    <div className="load-more-container">
                        <button 
                            className="load-more-btn"
                            onClick={loadMore}
                        >
                            Voir plus
                        </button>
                    </div>
                )}
            </section>

            {currentUser && (
                <button 
                    className="new-discussion-button" 
                    title="Nouvelle discussion"
                    onClick={() => setShowModal(true)}
                >
                    <FaPlus />
                </button>
            )}

            <NouvDiscModal 
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                forumId="nlp-discussions"
                onDiscussionUpdate={refreshDiscussions}
            />
        </main>
    );
};

export default NLP;
